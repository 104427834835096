const Vmi = () =>{
     return(
        <>
             {/* start breadcrumb area */}
             <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">VMI Website</h1>
                            <p className="sub-title font-static-white">
                            Explore the brilliance and elegance of exquisite diamonds on our captivating diamond<br/>
                            website, where each gem tells a unique story of timeless beauty.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row"  style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/vmi-diamonds-2.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About VMI Website</span>
                                    <h3 className="title">VMI Website</h3>
                                    <p className="disc">
                                    Step into a world of timeless allure on our diamond website, where the brilliance of these rare gems takes center stage. Indulge your senses in a meticulously curated collection that showcases the exceptional craftsmanship and natural beauty of each diamond. With a range of shapes, sizes, and settings, our platform caters to diverse preferences, whether you're celebrating a milestone or simply appreciating the elegance that diamonds bring to life.
                                    </p>
                                    <p className="disc">
                                        Immerse yourself in a visual journey through high-quality images and detailed specifications that unveil the distinct qualities of each diamond. From engagement rings that symbolize enduring love to exquisite jewelry pieces that radiate sophistication, our diamond website embraces the artistry behind these precious stones. Navigate the realm of diamond knowledge, gaining insights into the 4 Cs and empowering yourself to make choices that resonate with your style. Whether you're seeking an expression of devotion or a personal indulgence, our platform invites you to explore the tales woven into each exquisite gem, commemorating their transformation from nature's treasure to breathtaking adornments.
                                     </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">VMI Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                The diamond website presents a curated collection, educational insights, and customization options, guiding visitors through the brilliance of exquisite gemstones.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details appointment-img">
                                <img
                                    src={require('./images/gallery/inner-image/vmi-diamonds-1.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Diamond Collection:</h5>
                                    <p className="disc">
                                     Showcase a wide variety of diamonds, including different shapes, sizes, and cuts, with detailed images and specifications.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Educational Resources: </h5>
                                    <p className="disc">
                                     Provide information about the 4 Cs (carat, cut, color, and clarity), diamond grading, and certification, helping customers make informed decisions.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Jewelry Selection:</h5>
                                    <p className="disc">
                                     Display a range of jewelry pieces featuring diamonds, including engagement rings, necklaces, earrings, and bracelets.
                                    </p>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Virtual Try-On: </h5>
                                    <p className="disc">
                                     Implement a virtual try-on feature that allows customers to visualize how different diamond jewelry would look on them.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Detailed Descriptions:</h5>
                                    <p className="disc">
                                     Provide comprehensive descriptions of each diamond's characteristics, origin, and unique qualities.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Wishlist: 
                                    </h5>
                                    <p className="disc">
                                   Enable users to create and manage wishlists of their favorite diamonds and jewelry pieces.
                                    </p>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img
                                    src={require('./images/gallery/inner-image/banner/vmi-diamonds.webp')}
                                    alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* project details image area end */}
        </>
     )
}
export default Vmi