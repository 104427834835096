import { Link } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa"
const Ourservice = () => {
  return (
    <>
      <>
        {/* start breadcrumb area */}
        <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                <h1 className="title">Services</h1>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="bread-tag">
                  <Link to="/" className="active">Home</Link>
                  <span> / </span>
                  <Link to="/service">
                    Services
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end breadcrumb area */}
        {/* our service area start */}
        <div className="rts-service-area rts-section-gapTop pb--200 service-two-bg bg_image">
          <div className="container">
            <div className="row g-5 service padding-controler">
              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100 mb-3">
                <div className="service-two-inner">
                  <Link to="/web-design" target='_blank' className="thumbnail five">
                    <img
                      src={require('./images/service/sub-inner-img/web_designing.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">Web Designing</h5>
                      <p className="dsic">
                        At Webvision, we take pride in crafting captivating and
                        cutting-edge Web Designs that bring your brand's vision to
                        life.
                      </p>
                      <Link to="/web-design" target='_blank'
                        className="rts-read-more-two color-primary"
                      >
                        Read More
                        <i className="far" >
                          <FaArrowRight />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}
              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100 mb-3 ">
                <div className="service-two-inner">
                  <Link to="/ui-ux" target='_blank' className="thumbnail six">
                    <img
                      src={require('./images/service/sub-inner-img/ui_ux_designing.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">UI-UX Designing</h5>
                      <p className="dsic">
                        Step into a world of captivating digital experiences with our
                        bespoke UI-UX Designing services.
                      </p>
                      <Link to="/ui-ux" target='_blank'
                        className="rts-read-more-two color-primary"
                      >
                        Read More
                        <i className="far" >
                          <FaArrowRight />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}
              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100 mb-3">
                <div className="service-two-inner">
                  <Link to="/graphics" target='_blank' className="thumbnail seven">
                    <img
                      src={require('./images/service/sub-inner-img/graphics_designing.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">Graphics Designing</h5>
                      <p className="dsic">
                        Welcome to our Graphics Designing studio, where creativity
                        meets strategy to bring your brand's story to life.
                      </p>
                      <Link to="/graphics" target='_blank'
                        className="rts-read-more-two color-primary"
                      >
                        Read More
                        <i className="far" >
                          <FaArrowRight />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}
              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100 mb-3 ">
                <div className="service-two-inner">
                  <Link to="/logo" target='_blank' className="thumbnail eight">
                    <img
                      src={require('./images/service/sub-inner-img/logo_designing.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">Logo Designing</h5>
                      <p className="dsic">
                        Unlock the Artistry of Your Brand with Our Bespoke Logo
                        Designing. Our team of visionary designers will..
                      </p>
                      <Link to="/logo" target='_blank' className="rts-read-more-two color-primary">
                        Read More
                        <i className="far" >
                          <FaArrowRight />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}
              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100 mb-3">
                <div className="service-two-inner">
                  <Link to="/web-development" target='_blank' className="thumbnail">
                    <img
                      src={require('./images/service/sub-inner-img/web_development.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">Web Development</h5>
                      <p className="dsic">
                        As a Web Development company, we specialize in crafting
                        dynamic and user-centric websites that...
                      </p>
                      <Link to="/web-development" target='_blank'
                        className="rts-read-more-two color-primary"
                      >
                        Read More
                        <FaArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}
              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100 mb-3">
                <div className="service-two-inner">
                  <Link to="/app-development" target='_blank' className="thumbnail four">
                    <img
                      src={require('./images/service/sub-inner-img/app_development.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">App Development</h5>
                      <p className="dsic">
                        At the heart of our Webvision(Company) lies an unwavering
                        commitment to crafting extraordinary Mobile Applications..
                      </p>
                      <Link to="/app-development" target='_blank'
                        className="rts-read-more-two color-primary"
                      >
                        Read More
                        <i className="far" >
                          <FaArrowRight />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}
              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100 mb-3">
                <div className="service-two-inner">
                  <Link to="/sass" target='_blank' className="thumbnail two">
                    <img
                      src={require('./images/service/sub-inner-img/SAAS_development.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">SAAS Development</h5>
                      <p className="dsic">
                        Welcome to our SAAS Development services, where innovation
                        meets functionality to empower your...
                      </p>
                      <Link to="/sass" target='_blank'
                        className="rts-read-more-two color-primary"
                      >
                        Read More
                        <FaArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}

              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100 mb-3">
                <div className="service-two-inner">
                  <Link to="/cms" target='_blank' className="thumbnail three">
                    <img
                      src={require('./images/service/sub-inner-img/CMS_development.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">CMS Development</h5>
                      <p className="dsic">
                        "Welcome to our CMS Development services, where we
                        revolutionize your website management experience.
                      </p>
                      <Link to="/cms" target='_blank'
                        className="rts-read-more-two color-primary"
                      >
                        Read More
                        <FaArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}
              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--60 mb-3">
                <div className="service-two-inner">
                  <Link to="/payment-gateway" target='_blank' className="thumbnail eleven">
                    <img
                      src={require('./images/service/sub-inner-img/Payment_Gateway _Integration.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">Payment Gateway Integration</h5>
                      <p className="dsic">
                        Transform your online business with our efficient and
                        comprehensive Payment Gateway Integration services.
                      </p>
                      <Link to="/payment-gateway" target='_blank'
                        className="rts-read-more-two color-primary"
                      >
                        Read More
                        <i className="far" >
                          <FaArrowRight />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}

              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--100 mb-3">
                <div className="service-two-inner">
                  <Link to="/digital" target='_blank' className="thumbnail nine">
                    <img
                      src={require('./images/service/sub-inner-img/Digital_marketing.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">Digital Marketing</h5>
                      <p className="dsic">
                        Step into a realm of Digital Marketing beyond ordinary
                        boundaries, where innovation and ingenuity ..
                      </p>
                      <Link to="/digital" target='_blank'
                        className="rts-read-more-two color-primary"
                      >
                        Read More
                        <i className="far" >
                          <FaArrowRight />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}
              {/* single service area */}
              <div className="col-xl-4 col-md-6 col-sm-12 col-12 pb--140 pb_md--60 mb-3 ">
                <div className="service-two-inner">
                  <Link to="/branding" target='_blank' className="thumbnail ten">
                    <img
                      src={require('./images/service/sub-inner-img/branding.webp')}
                      alt="Business_image"
                    />
                  </Link>
                  <div className="body-content">
                    <div className="hidden-area">
                      <h5 className="title">Branding</h5>
                      <p className="dsic">
                        As a company, we recognize the vital role that Branding plays
                        in establishing a strong presence in the market and..
                      </p>
                      <Link to="/branding" target='_blank'
                        className="rts-read-more-two color-primary"
                      >
                        Read More
                        <i className="far" >
                          <FaArrowRight />
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* single service area end*/}

            </div>
          </div>
        </div>
      </>

    </>
  )
}
export default Ourservice