import { Link } from "react-router-dom"
import { HiOutlineArrowRight } from "react-icons/hi"
import { useState } from "react";
const Paymentg = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

const handleMenuItemClick = () => {
    handleCloseSidebar(); // Close the sidebar when a menu item is clicked
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
    return (
        <>
            <>
                {/* start breadcrumb area */}
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">Payment Gateway Integration</h1>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="bread-tag">
                                <Link to="/" className="active">Home</Link>
                  <span> / </span>
                  <Link to="/service" className="active">Service</Link>
                                    <span> / </span>
                                    <Link>
                                        Payment Gateway Integration
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* start service details area */}
                <div className="rts-service-details-area rts-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                                {/* service details left area start */}
                                <div className="service-detials-step-1">
                                    <div className="thumbnail">
                                        <img
                                            src={require('./images/service/inner-imges/Payment_Gateway_Integration.webp')}
                                            alt="business-area"
                                        />
                                    </div>
                                    <h4 className="title">Payment Gateway Integration Services</h4>
                                    <p className="disc">
                                        Transform your online business with our efficient and
                                        comprehensive Payment Gateway Integration services.
                                    </p>
                                    <p className="disc">
                                        Our expert team is dedicated to tailoring a customized payment
                                        solution that aligns with your unique business requirements. By
                                        seamlessly integrating a reliable and cutting-edge payment gateway
                                        into your website or application, customers can make transactions
                                        with ease using various payment methods. Our top priority is
                                        security, and we take every measure to safeguard your customers'
                                        sensitive data.
                                    </p>
                                    <p className="disc">
                                        With industry-leading encryption and robust fraud prevention
                                        mechanisms, you can be confident that their information is
                                        protected. The benefits of Payment Gateway Integration extend
                                        beyond secure transactions. By providing a smooth and hassle-free
                                        payment experience, you enhance customer satisfaction, drive
                                        customer loyalty, and repeat business. The convenience of various
                                        payment options attracts new customers and expands your customer
                                        base.
                                    </p>
                                    <p className="disc">
                                        Our team of experienced professionals handles all technical
                                        aspects, ensuring a seamless integration that requires minimal
                                        effort from your end. With our Payment Gateway Integration
                                        services, you can focus on growing your business, knowing your
                                        online transactions are in safe hands. Boost your revenue, build
                                        customer trust, and watch your business thrive with our
                                        cutting-edge payment solutions tailored to your success.
                                    </p>
                                </div>
                                {/* service details left area end */}
                                <div className="row g-5 mt--30 mb--40">
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                     src={require('./images/service/icon/01.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">White Label Payment Gateway</h6>
                                                <p className="disc">
                                                    Webvision is a reliable White Label Payment Gateway that
                                                    enables quick and simple processing for acquirers, payment
                                                    service providers.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                     src={require('./images/service/icon/02.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">E-Commerce Payment API Solutions</h6>
                                                <p className="disc">
                                                    We have end-to-end safe and long-term payment instruments
                                                    for your online purchases through our eCommerce payment
                                                    solution.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/03.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">In-Store API Payment Integration</h6>
                                                <p className="disc">
                                                    Our in-store payment integration framework makes use of
                                                    powerful development suites, web and mobile SDKs,that enable
                                                    you to connect with the Platform in minutes.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                     src={require('./images/service/icon/04.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">
                                                    Payment Processing Security Solutions
                                                </h6>
                                                <p className="disc">
                                                    When activated, Our Platform applies hundreds of fraud rules
                                                    and external partners' fraud engines to every processed
                                                    transaction.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                </div>
                            </div>
                            {/*rts blog wizered area */}
                            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                {/* single wizered start */}
                <div className="rts-single-wized Categories service">
                  <div className="wized-header">
                    <h5 className="title">Our Services</h5>
                  </div>
                  <div className="wized-body">
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/web-design" onClick={handleMenuItemClick} >
                          Web Designing{" "}
                          <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/ui-ux" onClick={handleMenuItemClick}>
                          UI-UX Designing  <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/graphics" onClick={handleMenuItemClick}>
                          Graphics Designing
                          <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/logo" onClick={handleMenuItemClick}>
                          Logo Designing <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories">
                      <li>
                        <Link to="/web-development" onClick={handleMenuItemClick}>
                          Web Development <i className="far active"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/app-development" onClick={handleMenuItemClick}>
                          App Development <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories">
                      <li>
                        <Link to="/sass" onClick={handleMenuItemClick}>
                          SAAS Development<i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories">
                      <li>
                        <Link to="/cms" onClick={handleMenuItemClick}>
                          CMS Development <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    <ul className="single-categories">
                      <li>
                        <Link to="/payment-gateway" className="active" onClick={handleMenuItemClick}>
                          Payment Gateway Integration{" "}
                          <i className="far active"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris End */}
                    <ul className="single-categories">
                      <li>
                        <Link to="/digital" onClick={handleMenuItemClick}>
                          Digital Marketing
                          <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/branding" onClick={handleMenuItemClick}>
                          Branding
                          <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                  </div>
                </div>

                <div className="rts-single-wized contact service">
                  <div className="wized-header">
                    <Link to="/">
                      <img
                        src={require('./images/logo/white-logo.png')}
                        alt="Business_logo"
                      />
                    </Link>
                  </div>
                  <div className="wized-body">
                    <h5 className="title" >Need Help? We Are Here To Help You</h5>
                    <Link to="/contact" target="_blank" className="rts-btn btn-primary">
                      Contact Us
                    </Link>
                  </div>
                </div>
                {/* single wizered End */}
              </div>
                            {/* rts- blog wizered end area */}
                        </div>
                    </div>
                </div>
                {/* End service details area */}
            </>

        </>
    )
}
export default Paymentg;