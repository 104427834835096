
const Ornate = () =>{
    return(
       <>
               {/* start breadcrumb area */}
               <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">Ornate Website</h1>
                            <p className="sub-title font-static-white">
                            Experience excellence in dental care at Ornate Dental Hospital's website,  {" "}
                             
                                <br />
                                where innovation and compassion meet to create beautiful smiles.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row" style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/ornate-01.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About Ornate Website</span>
                                    <h3 className="title">Ornate Website</h3>
                                    <p className="disc">
                                    Step into the world of exceptional dental care through the Ornate Dental Hospital's website, where our commitment to innovation, expertise, and patient well-being shines brightly. With a blend of cutting-edge technology and compassionate dental professionals, our platform offers a gateway to comprehensive oral health solutions. Explore a range of specialized treatments, from routine check-ups and cosmetic dentistry to advanced procedures, all designed to ensure that every smile radiates confidence and vitality.  
                                    </p>
                                    <p className="disc">
                                    At Ornate Dental Hospital, we believe that dental care goes beyond clinical expertise—it's a personalized journey towards optimal oral health. Delve into a wealth of educational resources, patient testimonials, and informative articles that empower you to make informed decisions about your dental needs. Whether you're seeking preventive care, smile transformations, or restorative interventions, our website is a testament to our commitment to delivering excellence in every aspect of dental care. Discover the art of dentistry that blends precision with compassion, and embark on a path towards a healthier, more dazzling smile with Ornate Dental Hospital.

                                    </p>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50"  style={{alignItems:'center'}}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">Ornate Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                The Ornate Dental Hospital website features comprehensive services, patient testimonials, and educational resources, offering a holistic approach to dental care.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Patient Testimonials: </h5>
                                    <p className="disc">
                                     Display real-life experiences and success stories from satisfied patients, building trust and credibility.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Meet the Team:</h5>
                                    <p className="disc">
                                         Introduce the dental professionals, their qualifications, and areas of expertise, fostering a sense of familiarity and trust.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Treatment Information: </h5>
                                    <p className="disc">
                                     Provide detailed descriptions of each treatment, including benefits, procedures, and potential outcomes.
                                    </p>
                                </div>
                                {/* <div className="para">
                                    <h5 className="title">Assessment Tools: </h5>
                                    <p className="disc">
                                     Include quizzes, tests, and assignments to measure learners' comprehension and provide feedback on their progress.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/ornate-02.webp')}
                                  
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50"  style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 display-none">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/ornate-3.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Educational Resources:  </h5>
                                    <p className="disc">
                                    Offer informative articles, videos, and FAQs to educate visitors about oral health and various treatment options.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Before-and-After Gallery:</h5>
                                    <p className="disc">
                                     Showcase visual transformations through before-and-after photos of actual patients who have undergone treatments.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Virtual Consultations: </h5>
                                    <p className="disc">
                                     Offer virtual consultations for patients to discuss their concerns and treatment options remotely.
                                    </p>
                                </div>
                               
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img 
                               src={require('./images/gallery/inner-image/banner/ornate.webp')}
                                alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </>
    )
}
export default Ornate