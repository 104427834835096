import { FaCheck } from "react-icons/fa"
import { Link } from "react-router-dom"
import { HiOutlineArrowRight } from "react-icons/hi"
import { useState } from "react"
const Webdesign = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleCloseSidebar = () => {
        setIsSidebarOpen(false);
      };

    const handleMenuItemClick = () => {
        handleCloseSidebar(); // Close the sidebar when a menu item is clicked
        window.scrollTo(0, 0); // Scroll to the top of the page
      };
    return (
        <>
                {/* start breadcrumb area */}
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">Web Designing</h1>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="bread-tag">
                                    <Link to="/" className="active">Home</Link>
                                    <span> / </span>
                                    <Link to="/service" className="active">Service</Link>
                                    <span> / </span>
                                    <Link >
                                        Web Designing
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* start service details area */}
                <div className="rts-service-details-area rts-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                                {/* service details left area start */}
                                <div className="service-detials-step-1">
                                    <div className="thumbnail">
                                        <img
                                            src={require('./images/service/inner-imges/web_designing.webp')}
                                            alt="business-area"
                                        />
                                    </div>
                                    <h4 className="title">About Web Designing</h4>
                                    <p className="disc">
                                        At Webvision, we take pride in crafting captivating and
                                        cutting-edge web designs that bring your brand's vision to life.
                                        Our talented team of designers works collaboratively with you to
                                        understand your unique requirements and aspirations, ensuring that
                                        every element of your website is meticulously tailored to your
                                        brand identity and target audience.
                                    </p>
                                    <p className="disc">
                                        With a focus on seamless user experiences and stunning visuals, we
                                        create websites that not only impress but also drive results.
                                        Whether you need a new website, a redesign, or responsive
                                        optimization, we have the expertise to create a digital
                                        masterpiece that reflects your brand's essence and sets you apart
                                        in the online world.
                                    </p>
                                    <p className="disc">
                                        Elevate your online presence and leave a lasting impression with
                                        our exceptional web designing services. Let's collaborate to build
                                        a digital representation of your vision and unlock the full
                                        potential of your online success.
                                    </p>
                                </div>
                                <div className="row g-5 mt--30 mb--40">
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/01.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">Figma to HTML</h6>
                                                <p className="disc">
                                                    Skilled developers will ensure that the Figma design is
                                                    accurately translated into clean, well-structured HTML, CSS,
                                                    and JavaScript code.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/04.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">Responsive Design</h6>
                                                <p className="disc">
                                                    Webvision focuses on exceptional user experiences through
                                                    innovative web design solutions, utilizing responsive design
                                                    for seamless website adaptation across devices and
                                                    platforms. 
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/02.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">PSD to HTML</h6>
                                                <p className="disc">
                                                    PSD to HTML conversion allows customization of the website,
                                                    ensuring it aligns with the client's specific requirements.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    // src={require('./images/service/icon/04.webp')}
                                                    src={require('./images/service/icon/03.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">Website Re-Design</h6>
                                                <p className="disc">
                                                    The website redesign aims to create an immersive user
                                                    experience with cutting-edge design, seamless navigation,
                                                    and interactive elements.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                </div>
                                {/* service details left area end */}
                                <div className="service-detials-step-3 mt--70 mt_md--50">
                                    <div className="row g-5 align-items-center">
                                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="thumbnail sm-thumb-service">
                                                <img
                                                    src={require('./images/service/sm-01.jpg')}
                                                    alt="Service" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                            <h4 className="title">
                                                Our Website Designing Services Include:
                                            </h4>
                                            <ul className="list-unstyled benefits-two__points ml-0">
                                                <li>
                                                    <div className="icon insur-icon-svg">

                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Static Website Designing</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Responsive Website Designing</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Ecommerce Website Design</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>

                                                    </div>
                                                    <div className="text">
                                                        <p>Corporate Websites</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*rts blog wizered area */}
                            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                                {/* single wizered start */}
                                <div className="rts-single-wized Categories service">
                                    <div className="wized-header">
                                        <h5 className="title">Our Services</h5>
                                    </div>
                                    <div className="wized-body">
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/web-design" className="active" onClick={handleMenuItemClick}>
                                                    Web Designing{" "}
                                                    <i className="far active"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/ui-ux" onClick={handleMenuItemClick}>
                                                    UI-UX Designing  <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/graphics" onClick={handleMenuItemClick}>
                                                    Graphics Designing
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/logo" onClick={handleMenuItemClick}>
                                                    Logo Designing <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/web-development" onClick={handleMenuItemClick}>
                                                    Web Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/app-development" onClick={handleMenuItemClick}>
                                                    App Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/sass" onClick={handleMenuItemClick}>
                                                    SAAS Development<i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/cms" onClick={handleMenuItemClick}>
                                                    CMS Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/payment-gateway" onClick={handleMenuItemClick}>
                                                    Payment Gateway Integration{" "}
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris End */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/digital" onClick={handleMenuItemClick}>
                                                    Digital Marketing
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/branding" onClick={handleMenuItemClick}>
                                                    Branding
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                    </div>
                                </div>

                                <div className="rts-single-wized contact service">
                                    <div className="wized-header">
                                        <Link to="/">
                                            <img
                                                src={require('./images/logo/white-logo.png')}
                                                alt="Business_logo"
                                            />
                                        </Link>
                                    </div>
                                    <div className="wized-body">
                                        <h5 className="title">Need Help? We Are Here To Help You</h5>
                                        <Link to="/contact" target="_blank" className="rts-btn btn-primary">
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                                {/* single wizered End */}
                            </div>
                            {/* rts- blog wizered end area */}
                        </div>
                    </div>
                </div>
                {/* End service details area */}
            </>
    )
}
export default Webdesign