
const Pizza = () => {

    return(
        <>
           
            {/* start breadcrumb area */}
            <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">Pizza website</h1>
                            <p className="sub-title font-static-white">
                            Delicious pizza website showcasing a variety of mouthwatering flavors<br/> and customizable options.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row"  style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/pizza-3.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About Pizza</span>
                                    <h3 className="title">Pizza</h3>
                                    <p className="disc">
                                    Welcome to a pizza lover's paradise, where every bite is a journey to flavor heaven. Our pizza website is a celebration of the timeless culinary artistry that turns dough, sauce, and toppings into pure satisfaction. Dive into a menu that showcases a harmonious blend of classic favorites and innovative creations, all prepared with the freshest ingredients and a dash of passion. From the moment you set foot in our digital pizzeria, you're invited to explore a world where crusts are crispy, cheese is gooey, and toppings are a burst of colors and textures.
                                    </p>
                                    <p className="disc">
                                    Whether you're a fan of the tried-and-true Margherita or an adventurous spirit seeking a culinary thrill, our pizza offerings cater to all palates. Experience the joy of selecting your preferred crust, building your dream combination of toppings, and savoring a slice of handcrafted goodness that brings people together. As you navigate our user-friendly interface, you'll find mouthwatering visuals, pizza pairings with perfectly matched beverages, and the stories behind each recipe. Join us in embracing the culture, camaraderie, and sheer delight that a slice of pizza brings to life, making every meal an occasion to remember.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">Pizza Website Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                The pizza website features a mouthwatering menu, online ordering, customizable options, and fast delivery for a delightful pizza experience.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details appointment-img">
                                <img
                                    src={require('./images/gallery/inner-image/pizza-2.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title"> Menu Display:</h5>
                                    <p className="disc">
                                    Showcase a comprehensive menu of pizza options, including classic flavors, signature creations, and customizable choices.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Online Ordering:</h5>
                                    <p className="disc">
                                     Provide a user-friendly interface for customers to place orders online, select toppings, customize pizzas, and choose delivery or pickup options.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Visual Galleries:</h5>
                                    <p className="disc">
                                     Present high-quality images of each pizza, capturing their deliciousness and enticing customers to explore further.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title"> Special Offers:</h5>
                                    <p className="disc">
                                     Highlight ongoing promotions, deals, and combo offers to encourage customers to try different pizza combinations.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Pizza of the Month:</h5>
                                    <p className="disc">
                                     Feature a rotating "Pizza of the Month" that showcases a unique and seasonal creation.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Online Payments:</h5>
                                    <p className="disc">
                                     Facilitate secure online payment options, including credit/debit cards and digital wallets.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Events and Promotions:
                                    </h5>
                                    <p className="disc">
                                     Advertise upcoming events, themed nights, and special occasions hosted at your restaurant.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Blog and Recipes:
                                    </h5>
                                    <p className="disc">
                                     Share pizza-related articles, cooking tips, and recipes for those who want to recreate the magic at home.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img
                                    src={require('./images/gallery/inner-image/banner/pizza.webp')}
                                    alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* project details image area end */}
        </>
    )
 }
 export default Pizza