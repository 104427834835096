import { Link } from 'react-router-dom';

const Projectthree = () => {
  return (
    <>
      <>
        {/* start breadcrumb area */}
        <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                <h1 className="title">Portfolio Details</h1>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="bread-tag">
                  <Link to="/">Home</Link>
                  <span> / </span>
                  <Link className="active">
                    Portfolio Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end breadcrumb area */}
        {/* project details image area */}
        <div className="rts-project-details-area rts-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="big-bg-porduct-details">
                  <img
                    src={require('./images/product/bg-lg-04.png')}
                    alt="Business_Finbiz"
                  />
                  <div className="project-info">
                    <div className="info-head">
                      <h5 className="title">Project Information</h5>
                    </div>
                    <div className="info-body">
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-user" />
                        </div>
                        <div className="info-details">
                          <span>Project Name:</span>
                          <h6 className="name">Geeta Transformers</h6>
                        </div>
                      </div>
                      {/* end single info */}
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-user" />
                        </div>
                        <div className="info-details">
                          <span>Clients:</span>
                          <h6 className="name">Geeta</h6>
                        </div>
                      </div>
                      {/* end single info */}
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-layer-group" />
                        </div>
                        <div className="info-details">
                          <span>Category:</span>
                          <h6 className="name">Manufacturing company</h6>
                        </div>
                      </div>
                      {/* end single info */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt--70 mb--50">
              <div className="col-12">
                <div className="product-details-main-inner">
                  <span>Growth Solution</span>
                  <h3 className="title">Our Business Growth</h3>
                  <p className="disc">
                    In Geeta Transformer, items are processed from total 7 departments
                    to make a transformer and an item is created by using different
                    raw materials. In which more than 10 people are working in each
                    department, whose salary is determined according to how many
                    pieces they work on a day.
                  </p>
                  <p className="italic">
                    “Geeta Transformers is a transformer manufacturing company with
                    manufacturing units operating in 2 different Cities. A transformer
                    is something that is made using a lot of raw materials .”
                  </p>
                </div>
              </div>
            </div>
            <div className="row g-5 mb--60">
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-01.png')} alt="" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-02.png')} alt="" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-03.png')} alt="" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-12">
                <h3 className="title mb--0">The Challenge Of Project</h3>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pr--80">
                <div className="single-project-details-challenge">
                  <p className="details">
                    In this process, how much raw material they purchased, how much
                    material has been used by which department and which staff, how
                    much the staff earn today, how many pieces have the staff worked
                    on during the day, total how many pieces have been prepared, how
                    many pieces have been delivered, how many The pieces have been
                    ready to delivered, how many pieces are in process in which
                    department, how much the salary of the staff at the end of the
                    month has been taken online and we have tried to make the work of
                    the manager and owner of Geeta Transformer easier through
                    technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* project details image area end */}
      </>

    </>
  )
}
export default Projectthree;