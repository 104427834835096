import { Link } from "react-router-dom"
import { HiOutlineArrowRight } from "react-icons/hi"
import { FaCheck } from "react-icons/fa"
import { useState } from "react"
const Webdevelopment = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleCloseSidebar = () => {
      setIsSidebarOpen(false);
    };

  const handleMenuItemClick = () => {
      handleCloseSidebar(); // Close the sidebar when a menu item is clicked
      window.scrollTo(0, 0); // Scroll to the top of the page
    };
  return (
    <>
      <>
        {/* start breadcrumb area */}
        <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                <h1 className="title">Web Development</h1>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="bread-tag">
                  <Link to="/" className="active">Home</Link>
                  <span> / </span>
                  <Link to="/service" className="active">Service</Link>
                  <span> / </span>
                  <Link >
                    Web Development
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end breadcrumb area */}
        {/* start service details area */}
        <div className="rts-service-details-area rts-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                  <div className="thumbnail">
                    <img
                      src={require('./images/service/inner-imges/web_development.webp')}
                      alt="business-area"
                    />
                  </div>
                  <h4 className="title">About Web Development</h4>
                  <p className="disc">
                    As a web development company, we specialize in crafting dynamic
                    and user-centric websites that align with your unique business
                    objectives. Our team of skilled developers and designers work
                    collaboratively to bring your vision to life, ensuring a seamless
                    and engaging online experience for your visitors. We leverage the
                    latest technologies and industry best practices to create
                    responsive and mobile-friendly websites that cater to today's
                    digital-savvy audience.
                  </p>
                  <p className="disc">
                    With a strong focus on usability and aesthetics, we prioritize
                    creating visually stunning websites that not only capture
                    attention but also drive meaningful interactions and conversions.
                    Whether you need a simple brochure site or a complex e-commerce
                    platform, we have the expertise to deliver tailored solutions that
                    cater to your specific needs and preferences. Our approach is
                    customer-centric, and we actively involve you throughout the
                    development process to ensure that your website represents your
                    brand identity and conveys your message effectively.
                  </p>
                  <p className="disc">
                    From initial concept discussions to post-launch support, we are
                    committed to delivering exceptional service and a website that
                    serves as a powerful asset for your business. Partner with us, and
                    let our web development prowess elevate your online presence,
                    amplify your brand reach, and open up new opportunities for growth
                    in the ever-evolving digital landscape. Experience the difference
                    of a finely crafted website that not only impresses but also
                    drives tangible results for your business.
                  </p>
                </div>
                <div className="row g-5 mt--30 mb--40">
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src={require('./images/service/icon/02.webp')}
                          alt="icon"
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">PHP</h6>
                        <p className="disc">
                          We are known for building dynamic, database-driven and high
                          performance.
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src={require('./images/service/icon/04.webp')}
                          alt="icon"
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">ASP.NET</h6>
                        <p className="disc">
                          We offer .Net development services. We provide you the best
                          solution ASP.NET .
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src={require('./images/service/icon/03.webp')}
                          alt="icon"
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">REACT &amp; NODE JS</h6>
                        <p className="disc">
                          We have experienced ReactJS &amp; NodeJS Developers who
                          implement best solutions.
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                  <div className="col-lg-6">
                    {/* single service details card */}
                    <div className="service-details-card">
                      <div className="thumbnail">
                        <img
                          src={require('./images/service/icon/01.webp')}
                          alt="icon"
                          className="icon"
                        />
                      </div>
                      <div className="details">
                        <h6 className="title">Magento</h6>
                        <p className="disc">
                          Running an eCommerce site? Use Magento. Magento is a website
                          development platform.
                        </p>
                      </div>
                    </div>
                    {/* single service details card End */}
                  </div>
                </div>
                {/* service details left area end */}
                <div className="service-detials-step-3 mt--70 mt_md--50">
                  <div className="row g-5 align-items-center">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="thumbnail sm-thumb-service">
                        <img
                          src={require('./images/service/sm-01.jpg')}
                          alt="Service" />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                      <h4 className="title">
                        Our Website Development Services Include:
                      </h4>
                      <ul className="list-unstyled benefits-two__points ml-0">
                        <li>
                          <div className="icon insur-icon-svg">
                            <i> <FaCheck /> </i>
                          </div>
                          <div className="text">
                            <p>Responsive &amp; Professional Website</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon insur-icon-svg">
                            <i> <FaCheck /> </i>
                          </div>
                          <div className="text">
                            <p>Speed Enhancement By Optimizing Images</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon insur-icon-svg">
                            <i> <FaCheck /> </i>
                          </div>
                          <div className="text">
                            <p>Requirement Customized Solutions</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon insur-icon-svg">
                            <i> <FaCheck /> </i>
                          </div>
                          <div className="text">
                            <p>Testing &amp; Quality Assurance</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/*rts blog wizered area */}
              <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                {/* single wizered start */}
                <div className="rts-single-wized Categories service">
                  <div className="wized-header">
                    <h5 className="title">Our Services</h5>
                  </div>
                  <div className="wized-body">
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/web-design" onClick={handleMenuItemClick} >
                          Web Designing{" "}
                          <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/ui-ux" onClick={handleMenuItemClick}>
                          UI-UX Designing  <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/graphics" onClick={handleMenuItemClick}>
                          Graphics Designing
                          <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/logo" onClick={handleMenuItemClick}>
                          Logo Designing <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories">
                      <li>
                        <Link to="/web-development" className="active" onClick={handleMenuItemClick}>
                          Web Development <i className="far active"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/app-development" onClick={handleMenuItemClick}>
                          App Development <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories">
                      <li>
                        <Link to="/sass" onClick={handleMenuItemClick}>
                          SAAS Development<i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories">
                      <li>
                        <Link to="/cms" onClick={handleMenuItemClick}>
                          CMS Development <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    <ul className="single-categories">
                      <li>
                        <Link to="/payment-gateway" onClick={handleMenuItemClick}>
                          Payment Gateway Integration{" "}
                          <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris End */}
                    <ul className="single-categories">
                      <li>
                        <Link to="/digital" onClick={handleMenuItemClick}>
                          Digital Marketing
                          <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                    {/* single categoris */}
                    <ul className="single-categories active">
                      <li>
                        <Link to="/branding" onClick={handleMenuItemClick}>
                          Branding
                          <i className="far"> <HiOutlineArrowRight />  </i>
                        </Link>
                      </li>
                    </ul>
                    {/* single categoris End */}
                  </div>
                </div>

                <div className="rts-single-wized contact service">
                  <div className="wized-header">
                    <Link to="/">
                      <img
                        src={require('./images/logo/white-logo.png')}
                        alt="Business_logo"
                      />
                    </Link>
                  </div>
                  <div className="wized-body">
                    <h5 className="title">Need Help? We Are Here To Help You</h5>
                    <Link to="/contact" target="_blank" className="rts-btn btn-primary">
                      Contact Us
                    </Link>
                  </div>
                </div>
                {/* single wizered End */}
              </div>
              {/* rts- blog wizered end area */}
            </div>
          </div>
        </div>
        {/* End service details area */}
      </>

    </>
  )
}
export default Webdevelopment;