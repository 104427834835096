import { Link } from "react-router-dom";
const Ecommerce = () => {
  return (
    <>
      {/* start breadcrumb area */}
      <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">  
              <h1 className="title">E-Commerce website</h1>
              <p className="sub-title font-static-white">Dynamic e-commerce website offering a wide range of products for convenient online shopping.
              </p>
            </div>
           
          </div>
        </div>
      </div>
      {/* end breadcrumb area */}
 
      {/* project details image area */}
      <div className="rts-project-details-area rts-section-gap">
        <div className="container">
          <div className="row" style={{alignItems:'center'}}>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="big-bg-porduct-details">
                <img
                  src={require('./images/gallery/inner-image/ecommerce-1.webp')}
                  alt="T3 Web World"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="big-bg-porduct-details">
                <div className="product-details-main-inner">
                  <span>About e-Commerce</span>
                  <h3 className="title">E-Commerce</h3>
                  <p className="disc">
                  The e-commerce website provides a dynamic and user-friendly platform that caters to modern 
                  shopping trends, offering a diverse array of products to satisfy the needs and preferences 
                  of online shoppers. As users land on the homepage, they are greeted with an inviting design 
                  that showcases featured products, seasonal collections, and special offers. The visually 
                  appealing layout entices visitors to explore further, creating an engaging and immersive 
                  shopping experience from the start.

                  </p>
                  <p className="disc">
                  Navigation through the website is intuitive, facilitated by a well-structured 
                  menu that categorizes products into various sections. From electronics to fashion, 
                  home decor to beauty products, users can easily browse through the extensive product 
                  catalog. The search bar at the top allows for quick and specific searches, while filters 
                  enable users to narrow down their choices based on criteria such as price range, brand, 
                  and customer ratings.

                  </p>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rts-project-feature-area">
        <div className="container">
          <div className="row mb--50">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="product-details-main-inner mb-5 text-center">
                <h3 className="title">E-Commerce Features:</h3>
                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                  Our user-friendly interface is thoughtfully crafted to ensure a
                  seamless navigation experience, guiding you through a treasure
                  trove of invaluable insights.
                </p>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="big-bg-porduct-details appointment-img">
                <img
                  src={require('./images/gallery/inner-image/ecommerce-2.webp')}
                  alt="T3 Web World"
                />
             
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="product-details-main-inner">
                <div className="para">
                  <h5 className="title">Streamlined Shopping:</h5>
                  <p className="disc">
                  Seamlessly transition from browsing to purchasing with the option to 
                  save items to your wishlist or cart, permitting a hassle-free 
                  experience as you explore further.
                  </p>
                </div>
                <div className="para">
                  <h5 className="title">Intuitive Navigation:</h5>
                  <p className="disc">
                  Effortlessly explore the extensive product range through a meticulously organized menu, 
                  spanning categories from electronics to fashion, home decor to beauty products.
                  </p>
                </div>
             
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="product-details-main-inner">
                <div className="para">
                  <h5 className="title">Transparent Delivery:</h5>
                  <p className="disc">
                  Navigate shipping options effortlessly, aided by clear delivery time estimates, managing your expectations while awaiting your purchase.
                  </p>
                </div>
                <div className="para">
                  <h5 className="title">Comprehensive Support:</h5>
                  <p className="disc">
                  Prioritizing your needs, our dedicated "Contact Us" page offers multiple communication channels, from email 
                  to live chat. Swiftly seek assistance, resolve queries, or address concerns as they arise.
                  </p>
                </div>
 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rts-project-details-area">
        <div className="container-fulid">
          <div className="row">
            <div className="col-lg-12">
              <div className="thumbnail">
                <img src={require('./images/gallery/inner-image/banner/e-commerce.webp')} alt="T3 Web World" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* project details image area end */}
    </>
  )
}
export default Ecommerce;