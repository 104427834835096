
const Appointment = () => {
    return (
        <>
            <>
                {/* start breadcrumb area */}
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-12 col-lg-6 col-md- col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">Appointment System</h1>
                                <p className="sub-title font-static-white">
                                    Ride with Ease - Your Ultimate Urban Mobility Solution
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* project details image area */}
                <div className="rts-project-details-area rts-section-gap">
                    <div className="container">
                        <div className="row"  style={{alignItems:'center'}}>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="big-bg-porduct-details">
                                    <img
                                        src={require('./images/gallery/inner-image/appointment-2.webp')}
                                        alt="T3 Web World"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="big-bg-porduct-details">
                                    <div className="product-details-main-inner">
                                        <span>About Appointment System</span>
                                        <h3 className="title">Appointment System</h3>
                                        <p className="disc">
                                            The appointment website presents a streamlined and efficient
                                            platform designed to simplify the process of scheduling and
                                            managing appointments. As users land on the homepage, they are
                                            greeted with a clean and intuitive design that emphasizes ease
                                            of use. The website's user-friendly interface makes it simple
                                            for both service providers and clients to navigate through the
                                            various features.
                                        </p>
                                        <p className="disc">
                                            The heart of the website lies in its robust scheduling system.
                                            Service providers can create their profiles and list their
                                            available services, along with corresponding time slots. Clients
                                            can then browse these offerings and select a suitable
                                            appointment time, all displayed in a clear and organized manner.
                                            This eliminates the need for back-and-forth communication,
                                            saving time for both parties and reducing the risk of scheduling
                                            conflicts.
                                        </p>
                                        <p className="disc">
                                            For added convenience, the website may offer integration with
                                            popular calendar applications. This allows clients to sync their
                                            appointments with their personal calendars, ensuring they never
                                            miss an important meeting or commitment. Automated email or SMS
                                            notifications can also be sent to remind clients of upcoming
                                            appointments, minimizing no-shows and enhancing reliability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rts-project-feature-area">
                    <div className="container">
                        <div className="row mb--50">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="product-details-main-inner mb-5 text-center">
                                    <h3 className="title">Appointment System Features:</h3>
                                    <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                        This platform offers a seamless and efficient solution, aimed at
                                        simplifying appointment management while delivering a
                                        user-friendly interface that caters to service providers and
                                        clients alike.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="big-bg-porduct-details appointment-img">
                                    <img
                                        src={require('./images/gallery/inner-image/appointment-3.webp')}
                                        alt="T3 Web World"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="product-details-main-inner">
                                    <div className="para">
                                        <h5 className="title">Intuitive Design:</h5>
                                        <p className="disc">
                                            Step onto the homepage and immerse yourself in a clean,
                                            intuitive design that prioritizes ease of use. Navigating
                                            through the platform's features becomes second nature, making it
                                            accessible to all users.
                                        </p>
                                    </div>
                                    <div className="para">
                                        <h5 className="title">Robust Scheduling System:</h5>
                                        <p className="disc">
                                            At the core of the website lies a powerful scheduling system.
                                            Service providers can establish profiles, showcasing their
                                            services and corresponding time slots. Clients can easily
                                            explore these options, selecting their preferred appointment
                                            times from a well-organized display.
                                        </p>
                                    </div>
                                    <div className="para">
                                        <h5 className="title">Calendar Integration:</h5>
                                        <p className="disc">
                                            Enhance convenience with seamless integration into popular
                                            calendar applications. Clients can effortlessly synchronize
                                            their appointments with personal calendars, ensuring important
                                            meetings and commitments are never overlooked.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="product-details-main-inner">
                                    <div className="para">
                                        <h5 className="title">Diverse Industry Support:</h5>
                                        <p className="disc">
                                            Tailored to multiple sectors including healthcare, beauty, and
                                            consulting, the website offers dedicated sections, each equipped
                                            with specialized features.For instance, healthcare segments
                                            could accommodate medical record uploads, while beauty sections
                                            showcase portfolios with galleries of previous work.
                                        </p>
                                    </div>
                                    <div className="para">
                                        <h5 className="title">Ratings and Reviews:</h5>
                                        <p className="disc">
                                            Foster transparency and trust through an integrated ratings and
                                            reviews system. Clients can provide feedback and rate their
                                            experiences post-appointment, guiding other users and promoting
                                            high-quality services.This administrative control guarantees a
                                            comprehensive overview of commitments and availability.
                                        </p>
                                    </div>
                                    <div className="para">
                                        <h5 className="title">Responsive Customer Support:</h5>
                                        <p className="disc">
                                            Access a dedicated help center or utilize the live chat feature
                                            to address any inquiries or concerns promptly. The responsive
                                            support system ensures users can confidently navigate the
                                            platform, fostering a positive and hassle-free experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
                {/* project details image area end */}
            </>

        </>
    )
}
export default Appointment;