import { Link } from 'react-router-dom';

const Projecttwo = () => {
  return (
    <>
      <>
        {/* start breadcrumb area */}
        <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                <h1 className="title">Portfolio Details</h1>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="bread-tag">
                  <Link to="/">Home</Link>
                  <span> / </span>
                  <Link className="active">
                    Portfolio Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end breadcrumb area */}
        {/* project details image area */}
        <div className="rts-project-details-area rts-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="big-bg-porduct-details">
                  <img
                    src={require('./images/product/bg-lg-03.png')}
                    alt="Business_Finbiz"
                  />
                  <div className="project-info">
                    <div className="info-head">
                      <h5 className="title">Project Information</h5>
                    </div>
                    <div className="info-body">
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-user" />
                        </div>
                        <div className="info-details">
                          <span>Project Name:</span>
                          <h6 className="name">Swagatam Mobile</h6>
                        </div>
                      </div>
                      {/* end single info */}
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-user" />
                        </div>
                        <div className="info-details">
                          <span>Clients:</span>
                          <h6 className="name">Swagatam</h6>
                        </div>
                      </div>
                      {/* end single info */}
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-layer-group" />
                        </div>
                        <div className="info-details">
                          <span>Category:</span>
                          <h6 className="name">Mobile company</h6>
                        </div>
                      </div>
                      {/* end single info */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt--70 mb--50">
              <div className="col-12">
                <div className="product-details-main-inner">
                  <span>Growth Solution</span>
                  <h3 className="title">Our Business Growth</h3>
                  <p className="disc">
                    In this software, we have tried to simplify the process of mobile
                    services. In which any person comes to Swagatam Mobile to repair
                    his mobile, then we save the complete information of him and his
                    phone, along with the data of the engineer who will repair that
                    mobile ,The Engineer can see in his service panel how many mobile
                    repair work done by him, how many mobile remaining to service, how
                    much has been spent on the mobile.
                  </p>
                  <p className="italic">
                    “Swagatam Mobile is a company engaged in the business of mobile
                    sales and service. At Swagatam Mobile we helped to take the entire
                    process of their mobile service online and grow their business
                    through data analysis.”
                  </p>
                </div>
              </div>
            </div>
            <div className="row g-5 mb--60">
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img
                    src={require('./images/product/d-11.png')}
                    alt="product1" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-12.png')} alt="product1" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-13.png')} alt="product1" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-12">
                <h3 className="title mb--0">The Challenge Of Project</h3>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pr--80">
                <div className="single-project-details-challenge">
                  <p className="details">
                    When a mobile is repaired, it gets into the branch panel and from
                    there the authorized person of the branch can return the mobile to
                    the customer and at the same time, a text message is sent to the
                    customer's mobile by the system in which the service is received.
                    Includes a link to thank you and rate the service.
                  </p>
                  <p className="details">
                    Along with this, according to the number of phone repair work done
                    by an engineer during the month, his salary is also generated by
                    the system.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* project details image area end */}
      </>

    </>
  )
}
export default Projecttwo;