const Construction = () =>{
    return(
       <>
              {/* start breadcrumb area */}
              <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">Construction website</h1>
                            <p className="sub-title font-static-white">
                            Informative construction website offering insights into projects,  {" "}
                                <br />
                                expertise, and industry solutions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row" style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/construction-3.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About Construction website</span>
                                    <h3 className="title">Construction website</h3>
                                    <p className="disc">
                                    Discover a comprehensive construction website that serves as your ultimate destination for all things related to the dynamic world of construction. Whether you're an industry professional seeking in-depth insights or a passionate DIY enthusiast starting your next project, our platform is designed to meet your needs. Delve into a vast repository of expert knowledge, from architectural trends and structural nuances to sustainable practices and innovative materials. Our step-by-step guides and tutorials provide invaluable assistance, ensuring that your projects, big or small, are executed with precision and confidence. Stay current with the latest updates on regulations, technologies, and methodologies, and explore our project management resources to streamline your endeavors. Join us in fostering a thriving construction community where information and expertise converge seamlessly, empowering you to build the present and shape the future.
                                     </p>
                                    <p className="disc">
                                    Welcome to a virtual hub that caters to your construction cravings. Our construction website is a one-stop junction offering a multitude of resources for enthusiasts and professionals alike. Immerse yourself in a wealth of construction wisdom, covering diverse topics ranging from foundational principles to advanced techniques. Whether you're a curious beginner seeking guidance or a seasoned builder staying ahead of the curve, our platform equips you with the insights needed to elevate your projects. Navigate through our user-friendly interface to access expert advice, real-world case studies, and interactive tools that simplify project management. Embrace a community-driven atmosphere where construction aficionados collaborate, share experiences, and embark on journeys to turn visions into reality.
                                    </p>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50"  style={{alignItems:'center'}}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">Construction  Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                The construction website highlights projects, expertise, client testimonials, and industry insights, showcasing a comprehensive approach to construction services.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Expert Insights: </h5>
                                    <p className="disc">
                                     Access a library of articles, videos, and webinars from industry experts covering a wide range of construction topics, from design principles to advanced construction techniques.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Project Galleries:</h5>
                                    <p className="disc">
                                     Showcase completed projects to inspire and guide visitors, showcasing various styles, sizes, and types of construction endeavors.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Materials Database: </h5>
                                    <p className="disc">
                                     Comprehensive information about different construction materials, including their properties, applications, and sustainability factors.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/construction-2.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50"  style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 display-none">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/construction-1.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Construction Technology: </h5>
                                    <p className="disc">
                                     Explore emerging technologies like BIM (Building Information Modeling), VR (Virtual Reality), and AR (Augmented Reality) for planning, visualization, and project management.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Interactive Tools: </h5>
                                    <p className="disc">
                                    Provide tools like cost estimators, material calculators, and project timeline generators to assist users in planning and managing their projects effectively

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Multimedia Resources:</h5>
                                    <p className="disc">
                                     Offer video tutorials, 3D animations, and interactive simulations to enhance the learning and engagement experience.

                                    </p>
                                </div>
                                {/* <div className="para">
                                    <h5 className="title">Assessment Tools: </h5>
                                    <p className="disc">
                                     Include quizzes, tests, and assignments to measure learners' comprehension and provide feedback on their progress.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img 
                               src={require('./images/gallery/inner-image/banner/contruction.webp')}
                                alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </>
    )
}
export default Construction