import React, { useEffect, useState } from 'react';

function Loader() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setIsLoading(false);
      document.querySelector('body').classList.add('loaded');
    }, 1500); // 1 seconds

    return () => {
      clearTimeout(loaderTimeout);
    };
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <div className="loader-section section-left"></div>
          <div className="loader-section section-right"></div>
        </div>
      ) : (
        <div>
          {/* Your content after the loader */}
        </div>
      )}
    </div>
  );
}

export default Loader;
