import './App.css'; 
import Home from './pages/home';
import About from './pages/about';
import Ourservice from './pages/our-service';
import Careers from './pages/careers';
import Contact from './pages/contact';
// import { BrowserRouter, Routes, Route, } fserom "react-router-dom";
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
//import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Workshow from './pages/workshowcases';
import Ourteam from './pages/our-team';
import Webdesign from './pages/web-design';
import Uiux from './pages/ui-ux';
import Graphics from './pages/graphics';
import Logo from './pages/logo';
import Paymentg from './pages/payment-gateway';
import Branding from './pages/branding';
import Webdevelopment from './pages/web-development';
import Appdevelopment from './pages/app-development';
import Loader from './component/loder';
import Sass from './pages/sass';
import Cms from './pages/cms';
import Digital from './pages/digital';
import Ecommerce from './pages/e-commerce';
import Navbar from './component/navbar';
import ProgressBackToTop from './component/scrolltop';
import Footer from './component/footer';
import Projectone from './pages/project-details1';
import Projecttwo from './pages/project-details2';
import Projectthree from './pages/project-details3';
import Projectfour from './pages/project-details4';
import Projectfive from './pages/project-details5';
import Akheelam from './pages/akheelam';
import Solar from './pages/solar';
import T3Webworld from './pages/t3webworld';
import T3Supportsystem from './pages/t-supportsystem';
import Appointment from './pages/appointment';
import Fooddelivery from './pages/food-delivery ';
import Rideapp from './pages/rideapp';
import Iycci from './pages/iycci';
import Pizza from './pages/pizza';
import Education from './pages/eduction';
import Volfon from './pages/volfon';
import Unity from './pages/unity-fortune';
import Vmi from './pages/vmi-diamond';
import Akplus from './pages/akplus';
import Ornate from './pages/ornate';
import Construction from './pages/construction';
import Hdenterprise from './pages/hd-enterprise';

import Model from './pages/model';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    const disableContextMenu = (e) => {
      e.preventDefault();
    };

    // Add event listener to disable right-click on the entire document
    document.addEventListener('contextmenu', disableContextMenu);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
    };

  }, []);
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (
        e.key === 'F12' || // Prevent opening developer tools
        (e.ctrlKey && e.shiftKey && e.key === 'I') || // Prevent opening developer tools
        (e.ctrlKey && e.key === 'u') // Prevent viewing page source with Ctrl+U
      ) {
        e.preventDefault();
      }
    };

    const disableContextMenu = (e) => {
      e.preventDefault(); // Prevent right-click context menu
    };

    const disableTextSelection = () => {
      return false; // Prevent text selection
    };

    // Add event listeners to disable developer tools, context menu, and Ctrl+U
    window.addEventListener('keydown', handleKeyPress);
    window.addEventListener('contextmenu', disableContextMenu);

    // Disable text selection for the entire document
    document.onselectstart = disableTextSelection;

    // Clean up the event listeners and text selection when the component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      window.removeEventListener('contextmenu', disableContextMenu);
      document.onselectstart = null; // Restore default text selection behavior
    };
  }, []);


  return (
    <div>
      <Router basename="/">
        <Navbar />
        <Loader />
        <Routes>
          <Route exact="true" path="/" element={<Home />} />
          <Route exact="true" path="/AboutUs" element={<About />} />
          <Route exact="true" path="/service" element={<Ourservice />} />
          <Route exact="true" path="/careers" element={<Careers />} />
          <Route exact="true" path="/ContactUs" element={<Contact />} />
          <Route exact="true" path="/workshow" element={<Workshow />} />
          {/* <Route exact="true" path="/our-team" element={<Ourteam />} /> */}
          <Route exact="true" path="/web-design" element={<Webdesign />} />
          <Route exact="true" path="/ui-ux" element={<Uiux />} />
          <Route exact="true" path="/graphics" element={<Graphics />} />
          <Route exact="true" path="/logo" element={<Logo />} />
          <Route exact="true" path="/payment-gateway" element={<Paymentg />} />
          <Route exact="true" path="/branding" element={<Branding />} />
          <Route exact="true" path="/web-development" element={<Webdevelopment />} />
          <Route exact="true" path="/app-development" element={<Appdevelopment />} />
          <Route exact="true" path="/sass" element={<Sass />} />
          <Route exact="true" path="/cms" element={<Cms />} />
          <Route exact="true" path="/digital" element={<Digital />} />
          <Route exact="true" path="/e-commerce" element={<Ecommerce />} />
          <Route exact="true" path="/project-details1" element={<Projectone />} />
          <Route exact="true" path="/project-details2" element={<Projecttwo />} />
          <Route exact="true" path="/project-details3" element={<Projectthree />} />
          <Route exact="true" path="/project-details4" element={<Projectfour />} />
          <Route exact="true" path="/project-details5" element={<Projectfive />} />
          <Route exact="true" path="/akheelam" element={<Akheelam />} />
          <Route exact="true" path="/solar" element={<Solar />} />
          <Route exact="true" path="/t3webworld" element={<T3Webworld />} />
          <Route exact="true" path="/t3-supportsystem" element={<T3Supportsystem />} />
          <Route exact="true" path="/appointment" element={<Appointment />} />
          <Route exact="true" path="/food-delivery" element={<Fooddelivery />} />
          <Route exact="true" path="/rideapp" element={<Rideapp />} />
          <Route exact="true" path="/iycci" element={<Iycci />} />
          <Route exact="true" path="/pizza" element={<Pizza />} />
          <Route exact="true" path="/eduction" element={<Education />} />
          <Route exact="true" path="/volfon" element={<Volfon />} />
          <Route exact="true" path="/unity-fortune" element={<Unity />} />
          <Route exact="true" path="/vmi-diamond" element={<Vmi />} />
          <Route exact="true" path="/akplus" element={<Akplus />} />
          <Route exact="true" path="/ornate" element={<Ornate />} />
          <Route exact="true" path="/construction" element={<Construction />} />
          <Route exact="true" path="/hd-enterprise" element={<Hdenterprise />} />
          <Route exact="true" path="/model" element={<Model />} />
        </Routes>
        {/* Footer */}
        <Footer />
      </Router>
      <div>
        {/* progress Back to top */}
        <ProgressBackToTop />
        {/* progress Back to top End */}
      </div>
    </div>
  );
}

export default App;