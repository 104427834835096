const Hdenterprise = () =>{
    return(
       <>
            {/* start breadcrumb area */}
            <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md- col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">HD Enterprise Website</h1>
                            <p className="sub-title font-static-white">
                            We are a leading electric lights wholesaler, illuminating your spaces with a diverse range of  {" "}
                                <br />
                                high-quality lighting solutions for all your commercial and residential needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row" style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/hd-enterprize-1.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About HD Enterprise Website</span>
                                    <h3 className="title">HD Enterprise Website</h3>
                                    <p className="disc">
                                    Step into a world of illumination with our comprehensive range of electric lights designed to brighten every corner of your life. As a leading electric lights wholesaler, we pride ourselves on offering a diverse selection of lighting solutions that cater to both commercial and residential needs. From ambient lighting that creates inviting atmospheres to task lighting that enhances productivity, our collection spans a variety of styles, sizes, and technologies to illuminate spaces with efficiency and elegance.
                                  </p>
                                    <p className="disc">
                                    At our electric lights emporium, you'll discover lighting options that go beyond functionality, embracing aesthetics and innovation. Whether you're outfitting a cozy home, a bustling office, or a retail space, our lights are meticulously curated to harmonize with diverse interiors and architectural designs. With a commitment to energy efficiency, durability, and visual appeal, our electric lights stand as a testament to our dedication to lighting up your world with brilliance and sophistication. Experience the transformative power of light as you explore our array of fixtures, from classic designs to cutting-edge LED technologies, each crafted to elevate your spaces and enhance the way you perceive the world around you.
                                     </p>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50"  style={{alignItems:'center'}}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">HD Enterprise Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                The electric lights wholesaler website showcases a diverse range of high-quality lighting solutions, offering customization options, technical specifications, and expert guidance.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Diverse Lighting Solutions: </h5>
                                    <p className="disc">
                                     Provide a wide range of lighting options, including chandeliers, pendant lights, ceiling fixtures, wall sconces, floor lamps, and more.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Commercial and Residential Options: </h5>
                                    <p className="disc">
                                    Cater to both commercial spaces (offices, retail stores, hotels) and residential spaces (homes, apartments) with appropriate lighting solutions.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title"> LED Technology: </h5>
                                    <p className="disc">
                                    Offer energy-efficient LED lighting options that save energy and have a longer lifespan.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/hd-enterprize-2.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50"  style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 display-none">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/hd-enterprize-3.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Variety of Styles: </h5>
                                    <p className="disc">
                                     Provide lighting fixtures in various styles such as modern, contemporary, traditional, industrial, and minimalist to match different interior aesthetics.


                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title"> Size and Dimension Options: </h5>
                                    <p className="disc">
                                    Offer lighting fixtures in different sizes and dimensions to accommodate various room sizes and layouts.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Smart Lighting:</h5>
                                    <p className="disc">
                                     Offer smart lighting solutions that can be controlled remotely through apps or voice assistants.
                                    </p>
                                </div>
                                {/* <div className="para">
                                    <h5 className="title">Assessment Tools: </h5>
                                    <p className="disc">
                                     Include quizzes, tests, and assignments to measure learners' comprehension and provide feedback on their progress.
                                    </p>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img 
                               src={require('./images/gallery/inner-image/banner/hd-enterprise.webp')}
                                alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </>
    )
}
export default Hdenterprise