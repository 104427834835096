
const Akheelam = () => {
    return (
        <>
            <>
                {/* start breadcrumb area */}
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">Akheelam Export India Pvt. Ltd.</h1>
                                <p className="sub-title font-static-white">
                                    Akheelam Export India Pvt. Ltd. stands as a distinguished export
                                    enterprise.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* project details image area */}
                <div className="rts-project-details-area rts-section-gap">
                    <div className="container">
                        <div className="row"  style={{alignItems:'center'}}>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="">
                                    <img
                                        src={require('./images/gallery/inner-image/akheelam-1.webp')}
                                        alt="akheelam"
                                        style={{'borderRadius':'10px'}}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="big-bg-porduct-details">
                                    <div className="product-details-main-inner">
                                        <span>About Akheelam</span>
                                        <h3 className="title">Akheelam Export</h3>
                                        <p className="disc">
                                            Akheelam Export India Pvt. Ltd. stands as a distinguished export
                                            enterprise, exemplifying the name 'Akheelam'. We have
                                            orchestrated the creation of a dynamic and informative website
                                            for Akheelam Export India, designed with a singular focus: to
                                            ensure unfettered access to comprehensive insights about the
                                            company's offerings. Imbued within this platform are
                                            meticulously curated sections, each dedicated to a distinct
                                            product category, complete with intricate details.
                                        </p>
                                        <p className="disc">
                                            This approach empowers every user to gain an in-depth
                                            understanding of the diverse product range while facilitating
                                            seamless engagement with the Akheelam Export India team.nestled
                                            within the contours of the 'About' page lies an encapsulation of
                                            Akheelam Export India's complete narrative.
                                        </p>
                                        <p className="disc">
                                            It unfurls the tapestry of the company's history, tracing its
                                            transformative journey, espousing its steadfast core values, and
                                            illuminating the guiding light of the Akheelam Mission and
                                            vision.Orchestrated by the visionary, Shree Manishbhai, the
                                            driving force behind Akheelam Export India, his aspiration is
                                            profound—enabling global recognition and fostering connectivity.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row  mb--50 rts-section-gapTop ">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="product-details-main-inner text-center mb-5">
                                    <h3 className="title">Akheelam Features</h3>
                                    <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                        Elevating Global Connections through Digital Excellence.
                                    </p>
                                </div>
                            </div>
                            <div className='row flex-direction-column-reverse'>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <ul className="product-details-main-inner">
                                        <li className="para">
                                            <h5 className="title">Dynamic User Interface</h5>
                                        </li>
                                        <li className="para">
                                            <h5 className="title">Comprehensive Product Listings</h5>
                                        </li>
                                        <li className="para">
                                            <h5 className="title">In-depth Product Details</h5>
                                        </li>
                                        <li className="para">
                                            <h5 className="title">Engaging About Page</h5>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="big-bg-porduct-details">
                                        <img
                                            src={require('./images/gallery/inner-image/akheelam-2.webp')}
                                            alt="akheelam"
                                        />
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="rts-project-details-area">
                    <div className="container-fulid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="thumbnail">
                                    <img
                                        src={require('./images/gallery/inner-image/banner/akheelam-banner.webp')}
                                        alt="akheelam"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* project details image area end */}
            </>

        </>
    )
}
export default Akheelam;