const Model = () => {
    return (
        <>
            {/* start breadcrumb area */}
            <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">Neha Sorathiya Website</h1>
                            <p className="sub-title font-static-white">
                            Explore the world of Neha Sorathiya, the model known for her grace and versatility,<br/> through her captivating personal website.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/modeling-1.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About Neha Sorathiya Website</span>
                                    <h3 className="title">Neha Sorathiya Website</h3>
                                    <p className="disc">
                                    Step into the enchanting realm of Neha Sorathiya, a model renowned for her exceptional grace and versatility. Delve into a captivating journey through her personal website, where you'll uncover an array of striking visuals and insights into her dynamic career. From high-fashion editorials to evocative artistic collaborations, this platform offers a glimpse into the multifaceted world of a model who seamlessly navigates between diverse styles and genres, leaving an indelible mark on the fashion and entertainment industry.

                                    </p>
                                    <p className="disc">
                                    Neha Sorathiya's website is more than a portfolio—it's a reflection of an artist's evolution. Immerse yourself in a visual narrative that traverses runways, photoshoots, and creative endeavors, each showcasing her ability to embody various moods and concepts. With a portfolio that spans couture, commercial campaigns, and experimental projects, this space serves as a tribute to her dedication, talent, and transformative presence in the modeling sphere. Whether you're a fashion enthusiast or simply drawn to the art of expression, Neha Sorathiya's website invites you to experience the charisma and elegance that define her unique journey through the world of fashion and beauty.

                                    </p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">Neha Sorathiya Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                Neha Sorathiya's model website combines an exquisite portfolio, diverse style showcases, collaborative highlights, 
                                and engaging behind-the-scenes insights for an immersive modeling experience.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details appointment-img">
                                <img
                                    src={require('./images/gallery/inner-image/modeling-2.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Portfolio Showcase:</h5>
                                    <p className="disc">
                                     Display a curated collection of Neha Sorathiya's modeling work, including high-resolution images from various photoshoots and campaigns.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Model Biography:</h5>
                                    <p className="disc">
                                     Provide a detailed biography highlighting Neha Sorathiya's background, accomplishments, and journey in the modeling industry.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Versatile Styles:</h5>
                                    <p className="disc">
                                     Showcase her versatility by categorizing her work into different styles, such as fashion, editorial, commercial, and artistic.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Press and Features:</h5>
                                    <p className="disc">
                                     Showcase articles, interviews, and features from magazines, blogs, and media outlets that have covered Neha Sorathiya's modeling career.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Blog or Journal:</h5>
                                    <p className="disc">
                                     Maintain a blog or journal section where Neha Sorathiya can share insights, experiences, and thoughts related to her modeling journey.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Project Highlights: </h5>
                                    <p className="disc">
                                    Feature in-depth descriptions and behind-the-scenes insights for selected modeling projects.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Video Portfolio:</h5>
                                    <p className="disc">
                                     Include video clips from fashion shows, runway walks, and other modeling ventures to offer a dynamic visual experience.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Collaborations:
                                    </h5>
                                    <p className="disc">
                                     Highlight collaborations with photographers, designers, stylists, and other industry professionals.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Interactive Gallery: 
                                    </h5>
                                    <p className="disc">
                                    Implement an interactive gallery with filters and categories for easy navigation through her extensive portfolio.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Social Media Integration:
                                    </h5>
                                    <p className="disc">
                                     Link to Neha Sorathiya's social media profiles for users to connect and follow her latest updates.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img
                                    src={require('./images/gallery/inner-image/banner/modeling.webp')}
                                    alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* project details image area end */}
        </>
    )
}
export default Model;