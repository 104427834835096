

const Solar = () => {
  return (
    <>

      {/* start breadcrumb area */}
      <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
              <h1 className="title">Solar Website</h1>
              <p className="sub-title font-static-white">
                Informative solar website promoting sustainable energy solutions and
                solar technology
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* end breadcrumb area */}
      {/* project details image area */}
      <div className="rts-project-details-area rts-section-gap">
        <div className="container">
          <div className="row" style={{alignItems:'center'}}>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="big-bg-porduct-details">
                <img
                  src={require('./images/gallery/inner-image/solar-website-1.webp')}
                  alt="T3 Web World"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="big-bg-porduct-details">
                <div className="product-details-main-inner">
                  <span>About Solar Website</span>
                  <h3 className="title">Solar Website</h3>
                  <p className="disc">
                    The solar website serves as a comprehensive platform dedicated
                    to promoting sustainable energy solutions and showcasing the
                    potential of solar technology. Upon arriving at the homepage,
                    visitors are greeted with a visually appealing design that
                    underscores the website's commitment to clean and renewable
                    energy. The website's interface is designed to be user-friendly,
                    offering an intuitive navigation experience that guides users
                    through a wealth of valuable information.
                  </p>
                  <p className="disc">
                    The heart of the website lies in its comprehensive coverage of
                    solar technology. The "Solar Solutions" section highlights
                    various applications, ranging from residential solar panels to
                    large-scale solar farms. Each solution is accompanied by
                    detailed explanations, visual aids, and case studies that
                    showcase real-world examples of successful solar installations.
                    This educational approach helps users understand the benefits,
                    feasibility, and impact of integrating solar energy into their
                    lives or businesses.
                  </p>
                  <p className="disc">
                    A dedicated "Benefits of Solar" section outlines the
                    environmental, economic, and societal advantages of adopting
                    solar energy. From reducing carbon emissions to lowering energy
                    costs and creating jobs, the website presents a compelling case
                    for the widespread adoption of solar technology. Infographics,
                    interactive charts, and comparative data further illustrate the
                    positive impact of harnessing the power of the sun.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rts-project-feature-area">
        <div className="container">
          <div className="row mb--50">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="product-details-main-inner mb-5 text-center">
                <h3 className="title">Solar Features:</h3>
                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                  Our user-friendly interface is thoughtfully crafted to ensure a
                  seamless navigation experience, guiding you through a treasure
                  trove of invaluable insights.
                </p>
              </div>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="big-bg-porduct-details appointment-img">
                <img
                  src={require('./images/gallery/inner-image/soalr-website-2.webp')}
                  alt="T3 Web World"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="product-details-main-inner">
                <div className="para">
                  <h5 className="title">Solar Solutions Showcase:</h5>
                  <p className="disc">
                    Delve into the heart of our platform, where the "Solar
                    Solutions" section takes center stage. Unveil a spectrum of
                    solar applications, ranging from residential solar panels to
                    sprawling solar farms. Each solution is meticulously elucidated,
                    accompanied by vivid visuals and compelling case studies that
                    bring successful solar installations to life.
                  </p>
                </div>
                <div className="para">
                  <h5 className="title">The Human Connection:</h5>
                  <p className="disc">
                    Personal stories and testimonials infuse a human touch into our
                    platform. Immerse yourself in the experiences of individuals and
                    businesses that have harnessed the potential of solar energy.
                    These authentic narratives underscore the viability and potency
                    of solar technology, fostering a tangible connection to the
                    journey ahead.
                  </p>
                </div>
                <div className="para">
                  <h5 className="title">Support Beyond Boundaries:</h5>
                  <p className="disc">
                    Find solace in our "Contact Us" page, your gateway to expert
                    guidance, inquiries, and consultations. Live chat and intuitive
                    chatbot features offer immediate responses to common queries,
                    ensuring a smooth passage through your solar energy exploration.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="product-details-main-inner">
                <div className="para">
                  <h5 className="title">Guidance for Transition:</h5>
                  <p className="disc">
                    Our invaluable "Get Started" section extends a guiding hand to
                    those embarking on their solar journey. Navigate the path of
                    selecting, installing, and nurturing solar panels with
                    confidence. Uncover available incentives, explore financing
                    avenues, and navigate local regulations—unraveling the route
                    towards an energy source that's both sustainable and empowering.
                  </p>
                </div>
                <div className="para">
                  <h5 className="title">Benefits Amplified:</h5>
                  <p className="disc">
                    In our dedicated "Benefits of Solar" section, the canvas of
                    advantages unfolds. Witness the triad of environmental,
                    economic, and societal perks that the solar revolution heralds.
                    From slashing carbon emissions to curbing energy costs and
                    nurturing employment opportunities, our website presents a
                    resounding case for the widespread embrace of solar technology.
                  </p>
                </div>
                <div className="para">
                  <h5 className="title">
                    Empowerment through the Solar Calculator:
                  </h5>
                  <p className="disc">
                    Empower your decision-making with our interactive "Solar
                    Calculator." By inputting fundamental details about your
                    location and energy consumption, visualize potential savings and
                    gauge the ecological impact of transitioning to solar energy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rts-project-details-area">
        <div className="container-fulid">
          <div className="row">
            <div className="col-lg-12">
              <div className="thumbnail">
                <img 
                // src="assets/images/gallery/inner-image/" 
                src={require('./images/gallery/inner-image/banner/sarvayogam.webp')}
                alt="T3 Web World" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* project details image area end */}
    </>


  )
}
export default Solar