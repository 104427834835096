const T3Supportsystem = () => {
    return (
        <>
            {/* start breadcrumb area */}
            <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md- col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">T3 Support System</h1>
                            <p className="sub-title font-static-white">
                            T3Supportsystem is your all-in-one solution for efficient and personalized customer support,  {" "}

                                <br />
                                offering a seamless experience through a user-friendly interface.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row" style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/t3-support-system-1.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About T3 Support System</span>
                                    <h3 className="title">T3 Support System</h3>
                                    <p className="disc">
                                    The pinnacle of customer support platforms designed to elevate your user experience to unparalleled heights. With a commitment to seamless interaction and efficient issue resolution, our platform stands as a beacon of innovation in the realm of customer support. Step into a world where user concerns are met with swiftness and expertise, thanks to our intuitive ticket management system, comprehensive knowledge base, and real-time chat support. Whether you're seeking answers to frequently asked questions or personalized assistance for complex issues, our user-friendly interface ensures that your journey towards solutions is smooth and satisfying.

                                    </p>
                                    <p className="disc">
                                    At T3 Supportsystem, we believe that customer support is more than a service – it's a partnership. Engage with a thriving community in our discussion forums, where you can find peer-to-peer support, share insights, and collaborate on problem-solving. Our analytics-driven approach keeps us on the cutting edge, allowing us to continuously improve and adapt to user needs. Whether you're a business striving to enhance customer satisfaction or an individual seeking answers, T3 Supportsystem is your steadfast companion, committed to revolutionizing the way support is delivered.

                                    </p>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50"  style={{alignItems:'center'}}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">T3 Supportsystem Features</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                    Certainly, here are some key features that can be highlighted for
                                    the T3 Supportsystem project:
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Knowledge Base:</h5>
                                    <p className="disc">
                                    Provide a comprehensive database of articles, FAQs, and guides to help users find solutions independently.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Live Chat Support: </h5>
                                    <p className="disc">
                                        {" "}
                                        Offer real-time chat support for immediate assistance and resolution of user queries.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Email Support: </h5>
                                    <p className="disc">
                                     
                                        Enable users to contact support via email for more detailed or asynchronous communication.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/t3-support-system-2.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50"  style={{alignItems:'center'}}>
                      
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 display-none">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/t3-support-system-3.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                
                                <div className="para">
                                    <h5 className="title">Real-Time Notifications: </h5>
                                    <p className="disc">
                                        Notify users about ticket updates, responses, and resolution status in real time.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Customizable Templates: </h5>
                                    <p className="disc">
                                     Use customizable email templates for consistent communication and branding.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Service Level Agreements (SLAs): </h5>
                                    <p className="disc">
                                    Define SLAs to ensure timely response and resolution according to predefined standards.
                                    </p>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                {/* <img 
                                src="assets/images/gallery/inner-image/" 
                                alt="T3 Web World" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default T3Supportsystem;