import { Link } from 'react-router-dom';
import { BsFacebook } from "react-icons/bs"
import { BsLinkedin } from "react-icons/bs"
import { BsYoutube } from "react-icons/bs"
import { BsInstagram } from "react-icons/bs"
import { HiOutlineArrowRight } from "react-icons/hi"
import { LiaHotelSolid } from "react-icons/lia"
import { RiAdvertisementLine } from "react-icons/ri"
import { MdOutlineEngineering, MdOutlineFastfood, MdCastForEducation, MdOutlineLocalPharmacy } from "react-icons/md"

import { useState } from 'react';
const Footer = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleCloseSidebar = () => {
      setIsSidebarOpen(false);
    };

  const handleMenuItemClick = () => {
      handleCloseSidebar(); // Close the sidebar when a menu item is clicked
      window.scrollTo(0, 0); // Scroll to the top of the page
    };

  return (
    <>
      {/* rts footer area start */}
      <div className="rts-footer-area footer-one rts-section-gapTop bg-footer-one">
        <div className="container bg-shape-f1">
          {/* footer call to action area */}
          <div className="row">
            <div className="col-12">
              <div className="rts-cta-wrapper">
                <div className="background-cta">
                  <div className="row">
                    {/* cta-left */}
                    <div className="col-lg-6">
                      <div className="cta-left-wrapepr">
                        <p className="cta-disc">Latest Business Ideas</p>
                        <h3 className="title">Sign Up Newsletter</h3>
                      </div>
                    </div>
                    {/* cta left end */}
                    <div className="col-lg-6">
                      <form
                        action="https://formsubmit.co/support@webvisioninfotech.com"
                        method="post"
                        className="cta-input-arae"
                      >
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          required
                        />


                        <button className="rts-btn btn-primary">
                          Subscribe Now
                        </button>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* footer call to action area End */}
          {/* rts footer area */}
          <div className="row pt--120 pt_sm--80 pb--80 pb_sm--40">
            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
              <div className="footer-one-single-wized margin-left-65">
                <div className="wized-title">
                  <h5 className="title">
                    <a href="assets/images/logo/logo-1.png" />
                  </h5>
                  <img
                    src={require('../pages/images/logo/white-logo.png')}
                    alt="finbiz_footer"
                  />
                </div>
                <div className="post-wrapper">
                  <p>
                    Webvision is a leading and globally accepted IT solution
                    provider and currently at the forefront of offshore software
                    development to serve the people across the world.
                  </p>
                </div>
                <div className="site-footer__social">
                  <a
                    className="icon-svg"
                    href="https://www.linkedin.com/company/web-vision-infotech"
                    target="_blank"
                  >
                    <i aria-hidden="true" className="  fab ">
                      <BsLinkedin />
                    </i>
                  </a>
                  <a
                    className="icon-svg"
                    href="https://www.facebook.com/webvision22"
                    target="_blank"
                  >
                    <i aria-hidden="true" className="  fab " >
                      <BsFacebook />
                    </i>
                  </a>
                  <a
                    className="icon-svg"
                    href="https://www.youtube.com/channel/UC-aRB5UvF1kj1KuUS85qkQA"
                    target="_blank"
                  >
                    <i aria-hidden="true" className="  fab ">
                      <BsYoutube />
                    </i>
                  </a>
                  <a
                    className="icon-svg"
                    href="https://www.instagram.com/webvisioninfotech/"
                    target="_blank"
                  >
                    <i aria-hidden="true" className="  fab " >
                      <BsInstagram />
                    </i>
                  </a>
                </div>
              </div>
            </div>
            {/* footer mid area */}
            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
              <div className="footer-one-single-wized mid-bg">
                <div className="wized-title">
                  <h5 className="title">Industry Cover</h5>
                  <img
                    src={require('../pages/images/footer/under-title.png')}
                    alt="finbiz_footer"
                  />
                </div>
                <div className="site-footer__social">

                  <span className="tooltip tooltip-effect-1 me-4">
                    <span className="tooltip-item">
                      <a
                        className="icon-svg icon-png"

                      >
                        <i className="fa-regular fa-gem"></i>
                      </a>
                    </span>
                    <span className="tooltip-content">Jewellery</span>
                  </span>
                  <span className="tooltip tooltip-effect-1 me-4">
                    <span className="tooltip-item">
                      <a
                        className="icon-svg icon-png"
                      >
                        <i aria-hidden="true" className="  fab " >
                          <LiaHotelSolid />
                        </i>
                      </a>
                    </span>
                    <span className="tooltip-content">Hotel</span>
                  </span>
                  <span className="tooltip tooltip-effect-1 me-4">
                    <span className="tooltip-item">
                      <a
                        className="icon-svg icon-png"
                      >
                        <i className="fa-solid">
                          <MdOutlineLocalPharmacy />
                        </i>
                      </a>
                    </span>
                    <span className="tooltip-content">Pharma</span>
                  </span>
                  <span className="tooltip tooltip-effect-1 me-4">
                    <span className="tooltip-item">
                      <a
                        className=" icon-png-svg icon-png"
                      >
                        <i aria-hidden="true" className="  fab " >
                          <RiAdvertisementLine />
                        </i>
                      </a>
                    </span>
                    <span className="tooltip-content">Advertising</span>
                  </span>
                </div>


                <div className="site-footer__social">
                  <span className="tooltip tooltip-effect-1 me-4">
                    <span className="tooltip-item">
                      <a
                        className="icon-svg icon-png"

                      >
                        <i className="fa-solid">
                        <MdOutlineEngineering />
                        </i>
                      </a>
                    </span>
                    <span className="tooltip-content">Engineering</span>
                  </span>
                  <span className="tooltip tooltip-effect-1 me-4">
                    <span className="tooltip-item">
                      <a
                        className="icon-svg icon-png"
                      >
                        <i className="fa-solid fa-coins"></i>
                      </a>
                    </span>
                    <span className="tooltip-content">Finance</span>
                  </span>
                  <span className="tooltip tooltip-effect-1 me-4">
                    <span className="tooltip-item">
                      <a
                        className="icon-svg icon-png"
                      >
                        <i className="fa-solid">
                        <MdOutlineFastfood />
                        </i>
                      </a>
                    </span>
                    <span className="tooltip-content">Food</span>
                  </span>
                  <span className="tooltip tooltip-effect-1 me-4">
                    <span className="tooltip-item">
                      <a
                        className="icon-svg icon-png"
                      >
                        <i aria-hidden="true" className="  fab " >
                          <MdCastForEducation />
                        </i>
                      </a>
                    </span>
                    <span className="tooltip-content">Education</span>
                  </span>
                </div>
                <div className="quick-link-inner">

                </div>
              </div>
            </div>
            {/* footer mid area end */}
            {/* footer end area post */}
            <div className="col-xl-4 col-md-6 col-sm-12 col-12 mt_lg--20 mt_md--20">
              <div className="footer-one-single-wized">
                <div className="wized-title">
                  <h5 className="title">Quick Links</h5>
                  <img
                    src={require('../pages/images/footer/under-title.png')}
                    alt="finbiz_footer"
                  />
                </div>
                <div className="quick-link-inner">

                  <ul className="links">
                    <li>
                      <Link to="/about" className="mylink" onClick={handleMenuItemClick}>
                        <i className='far'><HiOutlineArrowRight /></i>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" className="mylink" onClick={handleMenuItemClick}>
                        <i className='far'><HiOutlineArrowRight /></i>
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="/service" className="mylink" onClick={handleMenuItemClick}>
                        <i className='far'><HiOutlineArrowRight /></i>
                        Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/careers" onClick={handleMenuItemClick}>
                        <i className='far'><HiOutlineArrowRight /></i>
                        Career
                      </Link>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

            {/* footer end area post end*/}
          </div>
          {/* rts footer area End */}
        </div>
        {/* copyright area start */}
        <div className="rts-copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="text-center">
                  <p>
                    Webvision Copyright © 2023. <span id="copyright" /> All rights
                    reserved.
                  </p>
                  <p> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* copyright area end */}
      </div>
      {/* rts footer area end */}
    </>
  )
}
export default Footer;