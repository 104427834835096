const Fooddelivery = () => {
  return (
    <>

      {/* start breadcrumb area */}
      <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
              <h1 className="title">Food Delivery App</h1>
              <p className="sub-title font-static-white">
                Seamless food delivery app for hassle-free ordering and delicious
                dining.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* end breadcrumb area */}
      {/* project details image area */}
      <div className="rts-project-details-area rts-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 col-12">
              <div className="big-bg-porduct-details">
                <img
                  src={require('./images/gallery/inner-image/food-ui-1.webp')}
                  alt="Webvision" style={{ height: '535px' }}
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12">
              <div className="big-bg-porduct-details">
                <div className="product-details-main-inner">
                  <span>About Food Delivery</span>
                  <h3 className="title">Food Delivery</h3>
                  <p className="disc">
                    The food delivery app boasts an exceptionally user-friendly and
                    visually appealing user interface that simplifies the entire
                    ordering process. Upon opening the app, users are greeted with a
                    clean and elegant home screen that showcases a curated selection
                    of restaurants, cuisines, and popular dishes. High-quality
                    images of appetizing meals entice users, making their
                    decision-making process more enjoyable.
                  </p>
                  <p className="disc">
                    Navigating through the app is a breeze, thanks to its intuitive
                    layout. The search bar prominently positioned at the top enables
                    users to swiftly find their preferred restaurants or dishes.
                    Filters based on cuisine type, dietary preferences, and price
                    range offer a personalized experience, catering to a wide range
                    of tastes.
                  </p>
                  <p className="disc">
                    Once a restaurant is chosen, the app's UI ensures a seamless
                    exploration of the menu. Scrolling through a visually rich menu
                    with detailed descriptions and vivid images aids users in making
                    informed choices. The intuitive design includes options for
                    customizations, ensuring that users can modify dishes according
                    to their preferences, be it extra toppings, spice levels, or
                    sides.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb--50 rts-section-gapTop">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="product-details-main-inner text-center mb-5">
                <h3 className="title">Food Features</h3>
                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                  Indulge in the epitome of hassle-free food ordering and exquisite
                  dining with our cutting-edge food delivery app.
                  <br />
                  Designed to perfection, our app presents an incredibly
                  user-friendly interface coupled with captivating visuals that
                  elevate the entire ordering journey.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="product-details-main-inner">
                <div className="para">
                  <h5 className="title">Curated Selection</h5>
                  <p className="disc">
                    Immerse yourself in a world of culinary delights as our home
                    screen greets you with an elegant array of handpicked
                    restaurants, diverse cuisines, and enticing signature dishes.
                    Each high-definition image of delectable creations transforms
                    decision-making into an enjoyable art.
                  </p>
                </div>
                <div className="para">
                  <h5 className="title">Intuitive Navigation</h5>
                  <p className="disc">
                    Seamlessly navigate through the app with a layout that
                    effortlessly guides your exploration. The strategically
                    positioned search bar accelerates the quest for your preferred
                    restaurants or dishes. Tailor your choices with filters
                    encompassing cuisine types, dietary preferences, and budget,
                    ensuring a personalized gastronomic adventure.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="big-bg-porduct-details">
                <img
                  src={require('./images/gallery/inner-image/food-ui-2.webp')}
                  alt="Webvision" style={{ height: '535px' }}
                />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="product-details-main-inner">
                <div className="para">
                  <h5 className="">Immersive Menu Exploration</h5>
                  <p className="disc">
                    Embark on a visual journey through our restaurant menus. Each
                    dish is accompanied by vivid imagery and detailed descriptions,
                    empowering you to make informed selections. Customize your
                    orders with ease, whether it's extra toppings, spice levels, or
                    choice of sides.
                  </p>
                </div>
                <div className="para">
                  <h5 className="">Effortless Checkout</h5>
                  <p className="disc">
                    Witness the epitome of efficiency and security during checkout.
                    Your cart showcases the chosen items alongside their prices,
                    inclusive of taxes or fees. A final review step guarantees order
                    accuracy before you proceed to payment. Our app supports diverse
                    payment options, from credit/debit cards and digital wallets to
                    the convenience of cash on delivery.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rts-project-details-area">
        <div className="container-fulid">
          <div className="row">
            <div className="col-lg-12">
              <div className="thumbnail">
                <img
                  src={require('./images/gallery/inner-image/banner/food-ui.webp')}
                  alt="swagatam"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* project details image area end */}
    </>


  )
}
export default Fooddelivery;