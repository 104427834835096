import React, { useEffect } from 'react';

function ProgressBackToTop() {
  useEffect(() => {
    const progressPath = document.querySelector('.progress-wrap path');
    const pathLength = progressPath.getTotalLength();
    progressPath.style.transition = 'none';
    progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
    progressPath.style.strokeDashoffset = pathLength;
    progressPath.getBoundingClientRect();
    progressPath.style.transition = 'stroke-dashoffset 10ms linear';

    const updateProgress = () => {
      const scroll = window.scrollY;
      const height = document.documentElement.scrollHeight - window.innerHeight;
      const progress = pathLength - (scroll * pathLength / height);
      progressPath.style.strokeDashoffset = progress;
    };

    updateProgress();
    window.addEventListener('scroll', updateProgress);

    const handleScroll = () => {
      const offset = 50;
      const isActive = window.scrollY > offset;
      const progressWrap = document.querySelector('.progress-wrap');
      if (isActive) {
        progressWrap.classList.add('active-progress');
      } else {
        progressWrap.classList.remove('active-progress');
      }
    };

    window.addEventListener('scroll', handleScroll);

    const handleProgressClick = (event) => {
      event.preventDefault();
      const duration = 550;
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    const progressWrap = document.querySelector('.progress-wrap');
    progressWrap.addEventListener('click', handleProgressClick);

    return () => {
      window.removeEventListener('scroll', updateProgress);
      window.removeEventListener('scroll', handleScroll);
      progressWrap.removeEventListener('click', handleProgressClick);
    };
  }, []);

  return (
    <div className="progress-wrap">
      <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
  );
}

export default ProgressBackToTop;
