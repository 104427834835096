import { Link } from 'react-router-dom';

const Projectone = () => {
    return (
        <>
            {/* start breadcrumb area */}
            <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">Portfolio Details</h1>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="bread-tag">
                                <Link to="/">Home</Link>
                                <span> / </span>
                                <Link className="active">
                                    Portfolio Details
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/product/bg-lg-01.png')}
                                    alt="Business_Finbiz"
                                />
                                <div className="project-info">
                                    <div className="info-head">
                                        <h5 className="title">Project Information</h5>
                                    </div>
                                    <div className="info-body">
                                        {/* single info */}
                                        <div className="single-info">
                                            <div className="info-ico">
                                                <i className="fas fa-user" />
                                            </div>
                                            <div className="info-details">
                                                <span>Project Name:</span>
                                                <h6 className="name">Avani Design</h6>
                                            </div>
                                        </div>
                                        {/* end single info */}
                                        {/* single info */}
                                        <div className="single-info">
                                            <div className="info-ico">
                                                <i className="fas fa-user" />
                                            </div>
                                            <div className="info-details">
                                                <span>Clients:</span>
                                                <h6 className="name">Avani</h6>
                                            </div>
                                        </div>
                                        {/* end single info */}
                                        {/* single info */}
                                        <div className="single-info">
                                            <div className="info-ico">
                                                <i className="fas fa-layer-group" />
                                            </div>
                                            <div className="info-details">
                                                <span>Category:</span>
                                                <h6 className="name">Embroidery Business</h6>
                                            </div>
                                        </div>
                                        {/* end single info */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--70 mb--50">
                        <div className="col-12">
                            <div className="product-details-main-inner">
                                <span>Growth Solution</span>
                                <h3 className="title">Our Business Growth</h3>
                                <p className="disc">
                                    In this software, We have tried to simplify the avani design work
                                    by ensuring various things like how much goods does the company
                                    take from the trader to make, when does it take, when does it
                                    return, in which unit the goods are made, how many goods are in
                                    process, how many goods have been made, how many goods have been
                                    delivered. how many goods are left, how much goods have fallen on
                                    which unit etc.
                                </p>
                                <p className="italic">
                                    “Avani Design is a company associated with textile industries to
                                    which we have provided customized software and supported them in
                                    taking their business online.”
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5 mb--60">
                        <div className="col-lg-4">
                            <div className="thumbnail">
                                <img
                                    src={require('./images/product/d-08.png')}
                                    alt="Avani" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="thumbnail">
                                <img src={require('./images/product/d-09.png')} alt="Avani" />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="thumbnail">
                                <img src={require('./images/product/d-10.png')} alt="Avani" />
                            </div>
                        </div>
                    </div>
                    <div className="row g-5">
                        <div className="col-12">
                            <h3 className="title mb--0">The Challenge Of Project</h3>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pr--80">
                            <div className="single-project-details-challenge">
                                <p className="details">
                                    Along with this, we have also created a software for Avani Design
                                    to maintain its account, In this software, We have tried to
                                    simplify the avani design accounting work by ensuring various
                                    things like, how much income came from where, where did it go, to
                                    keep a record of the expenses incurred during the day, how much
                                    expenses were incurred during the month and where. , from which
                                    party, when and how much payment came, how much is due, inter bank
                                    transfers, Personal expenses, salary expenses etc. It involves
                                    generating a balance sheet according to different categories of
                                    income and expenses.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* project details image area end */}
        </>

    )
}
export default Projectone;