const Rideapp = () => {
    return (
        <>
            <>
                {/* start breadcrumb area */}
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">Ride App</h1>
                                <p className="sub-title font-static-white">
                                    Ride with Ease - Your Ultimate Urban Mobility Solution
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* project details image area */}
                <div className="rts-project-details-area rts-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="big-bg-porduct-details">
                                    <img
                                        src={require('./images/gallery/inner-image/ride-2.webp')}
                                        alt="ride app"
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="big-bg-porduct-details">
                                    <div className="product-details-main-inner">
                                        <span>About Ride App</span>
                                        <h3 className="title"> Ride App</h3>
                                        <p className="disc">
                                            Experience the future of urban transportation with RideEase, the
                                            cutting-edge ride app designed to provide seamless and efficient
                                            journeys.
                                        </p>
                                        <p className="disc">
                                            The ride delivery app boasts a user-centric and efficient user
                                            interface designed to simplify the process of urban
                                            transportation. Upon launching the app, users are greeted with a
                                            well-organized home screen that presents them with various
                                            options for selecting their destination and ride preferences.
                                            The interface is clean and modern, enhancing the overall user
                                            experience.
                                        </p>
                                        <p className="disc">
                                            Navigating through the app is straightforward and user-friendly.
                                            The prominently placed search bar allows users to quickly input
                                            their desired destination, and the app's intelligent
                                            autocomplete feature suggests relevant locations for faster
                                            entry. Users can choose between different types of rides, such
                                            as standard, premium, or shared options, catering to their
                                            preferences and budget.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rts-project-feature-area">
                    <div className="container">
                        <div className="row mb--50">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="product-details-main-inner mb-5 text-center">
                                    <h3 className="title">Ride App Features</h3>
                                    <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                        let me take you on a journey through the key features of this
                                        remarkable application.
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="product-details-main-inner">
                                        <div className="para">
                                            <h5 className="title">
                                                Streamlined User Interface for Effortless Journeys
                                            </h5>
                                        </div>
                                        <div className="para">
                                            <h5 className="title">
                                                Effortless Navigation for Ultimate Convenience
                                            </h5>
                                        </div>
                                        <div className="para">
                                            <h5 className="title">
                                                Seamless In-Ride Experience with Enhanced Communication
                                            </h5>
                                        </div>
                                        <div className="para">
                                            <h5 className="title">
                                                Effortless Payments and Interactive Profiles
                                            </h5>
                                        </div>
                                        <div className="para">
                                            <h5 className="title">
                                                In Summary: Elevate Your Urban Journey with RideEase
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <div className="big-bg-porduct-details">
                                        <img
                                            src={require('./images/gallery/inner-image/ride-1.webp')}
                                            alt="ride app" style={{ height: '535px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rts-project-details-area">
                    <div className="container-fulid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="thumbnail">
                                    <img
                                        src={require('./images/gallery/inner-image/banner/ride-banne.webp')}
                                        alt="ride app" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* project details image area end */}
            </>

        </>
    )
}
export default Rideapp;