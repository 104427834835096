import { FaCheck } from "react-icons/fa"
import { Link } from "react-router-dom"
import { HiOutlineArrowRight } from "react-icons/hi"
import { useState } from "react"
const Graphics = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleCloseSidebar = () => {
        setIsSidebarOpen(false);
      };

    const handleMenuItemClick = () => {
        handleCloseSidebar(); // Close the sidebar when a menu item is clicked
        window.scrollTo(0, 0); // Scroll to the top of the page
      };
    return (
        <>
            <>
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">Graphics Designing</h1>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="bread-tag">
                                <Link to="/" className="active">Home</Link>
                  <span> / </span>
                  <Link to="/service" className="active">Service</Link>
                                    <span> / </span>
                                    <Link>
                                        Graphics Designing
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* start service details area */}
                <div className="rts-service-details-area rts-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                                {/* service details left area start */}
                                <div className="service-detials-step-1">
                                    <div className="thumbnail">
                                        <img
                                            src={require('./images/service/inner-imges/graphics_designing.webp')}
                                            alt="business-area"
                                        />
                                    </div>
                                    <h4 className="title">About Graphics Designing</h4>
                                    <p className="disc">
                                        Welcome to our Graphics Designing studio, where creativity meets
                                        strategy to bring your brand's story to life.
                                    </p>
                                    <p className="disc">
                                        Our client-centric approach ensures that we delve deep into your
                                        brand's essence, understanding your values, goals, and target
                                        audience. With this invaluable insight, our team of talented
                                        designers crafts visually stunning graphics that speak volumes
                                        about your brand's identity.
                                    </p>
                                    <p className="disc">
                                        From captivating logos and immersive web designs to eye-catching
                                        marketing materials and social media visuals, our tailored
                                        solutions elevate your brand's presence and leave a memorable
                                        impression on your audience. Partner with us to unlock the full
                                        potential of your brand through our bespoke Graphics Designing
                                        services. I
                                    </p>
                                </div>
                                <div className="row g-5 mt--30 mb--40">
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/02.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">Photoshop</h6>
                                                <p className="disc">
                                                    Collaborate closely with our graphics team, and
                                                    cross-functional departments to translate design concepts
                                                    into pixel-perfect Photoshop compositions.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/01.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">Web AI Design</h6>
                                                <p className="disc">
                                                    Increase your brand’s online presence with our ROI-driven
                                                    Web AI Design and development service across the globe.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                </div>
                                {/* service details left area end */}
                                <div className="service-detials-step-3 mt--70 mt_md--50">
                                    <div className="row g-5 align-items-center">
                                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="thumbnail sm-thumb-service">
                                                <img 
                                                src={require('./images/service/sm-01.jpg')}
                                                alt="Service" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                            <h4 className="title">
                                                Our Graphics Designing Services Include:
                                            </h4>
                                            <ul className="list-unstyled benefits-two__points ml-0">
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                         <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Web Design Agency</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Web UI Design</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                         <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Logo &amp; Branding</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*rts blog wizered area */}
                            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                                {/* single wizered start */}
                                <div className="rts-single-wized Categories service">
                                    <div className="wized-header">
                                        <h5 className="title">Our Services</h5>
                                    </div>
                                    <div className="wized-body">
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/web-design"onClick={handleMenuItemClick} >
                                                    Web Designing{" "}
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                            <Link to="/ui-ux"onClick={handleMenuItemClick}>
                                                    UI-UX Designing  <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                            <Link to="/graphics" className="active"onClick={handleMenuItemClick}>
                                                    Graphics Designing
                                                    <i className="far active"> <HiOutlineArrowRight />  </i>
                                            </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                            <Link to="/logo"onClick={handleMenuItemClick}>
                                                    Logo Designing <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                            <Link to="/web-development"onClick={handleMenuItemClick}>
                                                    Web Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                               <Link to="/app-development"onClick={handleMenuItemClick}>
                                                    App Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                               <Link to="/sass"onClick={handleMenuItemClick}>
                                                    SAAS Development<i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                               <Link to="/cms"onClick={handleMenuItemClick}>
                                                    CMS Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        <ul className="single-categories">
                                            <li>
                                               <Link to="/payment-gateway"onClick={handleMenuItemClick}>
                                                    Payment Gateway Integration{" "}
                                                   <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris End */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/digital"onClick={handleMenuItemClick}>
                                                    Digital Marketing
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                               <Link to="/branding"onClick={handleMenuItemClick}>
                                                    Branding
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                    </div>
                                </div>
                               
                                <div className="rts-single-wized contact service">
                                    <div className="wized-header">
                                        <Link to="/">
                                            <img
                                                src={require('./images/logo/white-logo.png')}
                                                alt="Business_logo"
                                            />
                                        </Link>
                                    </div>
                                    <div className="wized-body">
                                        <h5 className="title">Need Help? We Are Here To Help You</h5>
                                        <Link to="/contact" target="_blank" className="rts-btn btn-primary">
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                                {/* single wizered End */}
                            </div>
                            {/* rts- blog wizered end area */}
                        </div>
                    </div>
                </div>
                {/* End service details area */}
            </>

        </>
    )
}
export default Graphics;