// import Swiper core and required modules
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import icon01 from './images/gallery/icon/01.svg';
import callus from './images/about/call.svg';

import { FaCheck } from "react-icons/fa"
import { HiOutlineArrowRight } from "react-icons/hi"
import { useState } from 'react';

const Home = () => {
  let [fullname, pickName] = useState("");
  let [nameerror, updateNameError] = useState("");

  let [email, pickEmail] = useState("");
  let [emaileeerror, updateEmailError] = useState("");

  let [message, pickMessage] = useState("");
  let [messageeerror, updateMessageError] = useState("");

  let [subject, pickSubject] = useState("");
  let [subjecterror, updateSubjectError] = useState("");

  let[mobile,pickMobile] = useState("");
  let[mobileeerror, updateMobileError] = useState("");
  const save = () => {
    let formstatus = true

    if (fullname === "") {
      updateNameError("Required Name !");
      formstatus = false;
    } else {
      updateNameError("");

    }
    //email validation
    // let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(email)) {
      updateEmailError("Required Email  !");
      formstatus = false;
    } else {
      updateEmailError("");
    }

    //message validation
    if (message === "") {
      updateMessageError("Required Message  !");
      formstatus = false;
    } else {
      updateMessageError("");
    }
    if (formstatus === true) {
      alert("Plese waite submitting to server....");
    }
    //mobile no validation
    var moattern = /^[0]?[6789]\d{9}$/;
    if( ! moattern.test(mobile) ){
        updateMobileError("Required Mobile  !");
        formstatus = false; 
    }else{
        updateMobileError("");
    }
    //subject validation
    if (subject === "") {
      updateSubjectError("Required Subject  !");
      formstatus = false;
    } else {
      updateSubjectError("");
    }

    if (formstatus === true) {
      alert("Plese waite submitting to server....");
    }

  }

  useEffect(() => {
    loadIframe();
  }, []); // Empty dependency array to run the effect only once when the component is mounted

  function loadIframe() {
    const iframe = document.createElement('iframe');
    iframe.src = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d464.97650006641254!2d72.793859!3d21.199626!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f96e5fbbae9%3A0xe32b8ec43132af35!2sWebvision%20Infotech!5e0!3m2!1sen!2sin!4v1689764637213!5m2!1sen!2sin";
    iframe.width = 600;
    iframe.height = 450;
    iframe.style.border = '0';
    iframe.allowFullscreen = true;
    iframe.referrerPolicy = 'no-referrer-when-downgrade';

    const mapContainer = document.getElementById('map-container');
    mapContainer.innerHTML = ''; // Clear any previous content
    mapContainer.appendChild(iframe);
  }

  return (
    <div className="home-main-wrapper">
      <>
        {/* banner blank space area */}
        <div className="rts-banner-area rts-banner-one">
          <div className="swiper mySwiper banner-one">
            <div className="swiper-wrapper">
              <Swiper
                // install Swiper modules
                modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                // navigation
                // pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                autoplay={{ delay: 4000 }}
              // onSwiper={(swiper) => console.log(swiper)}
              // onSlideChange={() => console.log('slide change')}
              >
                <SwiperSlide>
                  <div className="swiper-slide">
                    {/* banner single content */}
                    <div className="banner-one-inner text-start">
                      <h1 className="title ">
                        Empowering Clients
                        <br /> with Web Solutions <br />
                        Driving Digital
                        <br />
                        <span> Success Together</span>
                      </h1>
                      <p className="disc banner-para">
                        Empowering web design and development services help clients
                        <br />
                        achieve goals, enhance online presence, and impact businesses.
                      </p>
                      <img src={require('./images/banner/shape/01.png')} className="shape-img one" alt="banner_business" />
                    </div>
                    {/* banner single content end */}
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide two">

                    <div className="banner-one-inner text-start">
                      <h1 className="title ">
                        Personalized Mobile App Development Services <br />
                        For Businesses On
                        <br />
                        <span>Preferred Platforms.</span>
                      </h1>
                      <p className="disc banner-para">
                        Experience tailored mobile app aevelopment services, catered{" "}
                        <br />
                        to your business needs and platform preferences
                      </p>
                      <img
                        className="shape-img one"
                        src={require('./images/banner/shape/01.png')}
                        alt="banner_business"
                      />
                    </div>

                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide three">

                    <div className="banner-one-inner text-start">
                      <h1 className="title ">
                        Enhance Your Online <br />
                        Presence &amp; Digital
                        <br /> Marketing <br />
                        <span>For Competitive Results. </span>
                      </h1>
                      <p className="disc banner-para">
                        Experience growth and success with comprehensive Digital <br />
                        Marketing Services, optimizing online presence and unlocking full
                        potential.
                      </p>
                      <img
                        className="shape-img one"
                        src={require('./images/banner/shape/01.png')}
                        alt="banner_business"
                      />
                    </div>

                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="swiper-pagination" />
          </div>
          <div className="animation-img">

            <img
              className="shape-img two"
              src={require('./images/banner/shape/02.png')}
              alt="banner_business"
            />
            <img
              className="shape-img three"
              src={require('./images/banner/shape/03.png')}
              alt="banner_business"
            />
          </div>
        </div>

        {/* banner blank space area end */}
        {/* rts about us section start */}
        <div className="rts-about-area rts-section-gap bg-about-sm-shape">
          <div className="container">
            <div className="row g-5 align-items-center">
              {/* about left */}
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-1 order-md-2 order-sm-2 order-2 mt_md--50 mt_sm--50">
                <div className="rts-title-area">
                  <p className="pre-title">More About Us</p>
                  <h2 className="title">
                    Unlock Your Company's Potential with Our Top-notch Global IT
                    Solutions.
                  </h2>
                </div>
                <div className="about-inner">
                  <p className="disc">
                    Webvision is a leading IT company committed to delivering
                    exceptional digital solutions that empower businesses to thrive in
                    the modern digital landscape. Established with a vision to
                    revolutionize the industry, we have successfully served a diverse
                    clientele with our innovative approach and expertise.
                  </p>
                  {/* start about success area */}
                  <div className="row about-success-wrapper">
                    {/* left wrapper start */}
                    <div className="col-lg-6 col-md-6">
                      <div className="single">
                        <i className="far">
                          <FaCheck />
                        </i>
                        <p className="details">Expert Team</p>
                      </div>
                      <div className="single">
                        <i className="far">
                          <FaCheck />
                        </i>
                        <p className="details">Innovation and Excellence</p>
                      </div>
                    </div>
                    {/* left wrapper end */}
                    <div className="col-lg-6 col-md-6">
                      <div className="single">
                        <i className="far">
                          <FaCheck />
                        </i>
                        <p className="details">Our Services</p>
                      </div>
                      <div className="single">
                        <i className="far">
                          <FaCheck />
                        </i>
                        <p className="details">Client Satisfaction</p>
                      </div>
                    </div>
                  </div>
                  {/* start about success area */}
                  {/* about founder & get in touch start */}
                  <div className="row about-founder-wrapper align-items-center mt--40">
                    {/* left area start */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="author-inner">
                        <div className="thumbnail">
                          <img
                            src={require('./images/about/sandip.png')}
                            alt="webvision_founder"
                          />
                        </div>
                        <div className="founder-details">
                          <div>
                            <h6 className="title">Sandip Koladiya</h6>
                          </div>
                          <span>Founder &amp; CEO</span>
                        </div>
                      </div>
                    </div>
                    {/* left area end */}
                    {/* right founder area */}
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_sm--20">
                      <div className="author-call-option">
                        <Link to="tel:+918980384148"> <img
                          className="authore-call"
                          src={callus}
                          alt="call_founder"
                        />
                        </Link>
                        <div className="call-details">
                          <span>Call us anytime</span>
                          <Link to="tel:+918980384148">
                            <h6 className="title">+91 8980384148</h6>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* left founder area */}
                  </div>
                  {/* about founder & get in touch end */}
                </div>
              </div>
              {/* about right */}
              {/* about-right Start*/}
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 order-lg-2 order-md-1 order-sm-1 order-1">
                <div className="about-one-thumbnail">
                  <img
                    src={require('./images/about/main/about-01.webp')}
                    alt="about-webvision"
                  />
                  <img
                    className="small-img"
                    src={require('./images/about/main/about-02.webp')}
                    alt="webvision-small"
                  />
                  <div className="experience">
                    <div className="left single">
                      <h2 className="title">10+</h2>
                    </div>
                    <div className="right single">
                      <p className="disc">
                        years Of <br />
                        experience
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* about-right end */}
            </div>
          </div>
        </div>
        {/* rts about us section end */}
        {/* rts service post area  Start*/}
        <div className="rts-service-area rts-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="rts-title-area service text-center">
                  <p className="pre-title">Our Expertise</p>
                  <h2 className="title">
                    Discover a Multitude of Services at{" "}
                    <span style={{ color: "var(--color-primary)" }}>Webvision</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid service-main plr--120-service mt--50 plr_md--0 pl_sm--0 pr_sm--0">
            <div className="background-service row">
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner one">
                  <div className="thumbnail">
                    <img
                      src={require('./images/service/web-design.png')}
                      alt="webvision_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to="/web-design"
                      target="_blank"
                      className="mylink sub-menu-link">
                      <h5 className="title">Web Designing</h5>
                    </Link>
                    <p className="disc">
                      At Webvision, we take pride in crafting captivating and
                      cutting-edge web designs that bring your brand's vision to
                      life....
                    </p>
                    <Link to="/web-design" target="_blank" className="rts-read-more btn-primary">
                      <i className="far" >
                        <HiOutlineArrowRight />
                      </i>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner two">
                  <div className="thumbnail">
                    <img
                      src={require('./images/service/web-development.png')}
                      alt="webvision_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to="/web-development" target="_blank">
                      <h5 className="title">Web Developments</h5>
                    </Link>
                    <p className="disc">
                      As a web development company, we specialize in crafting dynamic
                      and user-centric websites that align with your unique..
                    </p>
                    <Link to="/web-development"
                      className="rts-read-more btn-primary"
                      target="_blank"
                    >
                      <i className="far" >
                        <HiOutlineArrowRight />
                      </i>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner three">
                  <div className="thumbnail">
                    <img
                      src={require('./images/service/saas.png')}
                      alt="webvision_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to="/sass" target="_blank">
                      <h5 className="title">SAAS Developments</h5>
                    </Link>
                    <p className="disc">
                      Welcome to our SAAS Development services, where innovation meets
                      functionality to empower your business for ...
                    </p>
                    <Link to="/sass"
                      className="rts-read-more btn-primary"
                      target="_blank"
                    >
                      <i className="far" >
                        <HiOutlineArrowRight />
                      </i>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner four">
                  <div className="thumbnail">
                    <img
                      src={require('./images/service/cms.png')}
                      alt="webvision_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to="/cms" target="_blank">
                      <h5 className="title">CMS Developments</h5>
                    </Link>
                    <p className="disc">
                      "Welcome to our CMS Development services, where we revolutionize
                      your website management experience...
                    </p>
                    <Link to="/cms"
                      target="_blank"
                      className="rts-read-more btn-primary"
                    >
                      <i className="far" >
                        <HiOutlineArrowRight />
                      </i>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner five">
                  <div className="thumbnail">
                    <img
                      src={require('./images/service/payment.png')}
                      alt="webvision_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to="/payment-gateway" target="_blank">
                      <h5 className="title">Payment gateway integration</h5>
                    </Link>
                    <p className="disc">
                      Transform your online business with our efficient and
                      comprehensive...
                    </p>
                    <Link to="/payment-gateway"
                      className="rts-read-more btn-primary"
                      target="_blank"
                    >
                      <i className="far" >
                        <HiOutlineArrowRight />
                      </i>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
              {/* start single Service */}
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="service-one-inner six">
                  <div className="thumbnail">
                    <img
                      src={require('./images/service/ui-ux.png')}
                      alt="webvision_service"
                    />
                  </div>
                  <div className="service-details">
                    <Link to="/ui-ux" target="_blank">
                      <h5 className="title">UI-UX Designing</h5>
                    </Link>
                    <p className="disc">
                      "Step into a world of captivating digital experiences with our
                      bespoke UI-UX Designing services.
                    </p>
                    <Link to="/ui-ux" className="rts-read-more btn-primary" target="_blank">
                      <i className="far" >
                        <HiOutlineArrowRight />
                      </i>
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* end single Services */}
            </div>
            <div className="row">
              <div className="cta-one-bg col-12">
                <div className="cta-one-inner">
                  <div className="cta-left">
                    <h3 className="title">
                      Let’s discuss about, how we can help make your business better
                    </h3>
                  </div>
                  <div className="cta-right">
                    <Link to="/contact" target="_blank" className="rts-btn btn-white">
                      Call Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* rts service post area ENd */}
        {/* business goal area */}
        <div className="rts-business-goal mt--0 rts-section-gapBottom">
          <div className="container">
            <div className="row">
              {/* business goal left */}
              <div className="col-lg-6">
                <div className="business-goal-one">
                  <img src={require('./images/business-goal/01.webp')} alt="Business_Goal" />
                  <img
                    className="small"
                    src={require('./images/business-goal/sm-01.webp')}
                    alt="Business_Goal"
                  />
                </div>
              </div>
              {/* business goal right */}
              {/* right area business */}
              <div className="col-lg-6 mt--35 mt_md--70 mt_sm--70">
                <div className="business-goal-right">
                  <div className="rts-title-area business text-start pl--30 pl_sm--0">
                    <p className="pre-title">Key Features</p>
                    <h2 className="title">
                      Empowering your Business with Cutting-edge Solutions
                    </h2>
                  </div>
                  <div className="rts-business-goal pl--15">
                    <div className="single-goal">
                      <img
                        src={require('./images/feature/experts.webp')}
                        alt="business_Icone"
                        className="thumb"
                      />
                      <div className="goal-wrapper">
                        <h6 className="title">Industry Experts</h6>
                        <p className="disc">
                          We have employees have 10+ years of industry experience
                          coupled with extensive research and analysis.
                        </p>
                      </div>
                    </div>
                    <div className="single-goal">
                      <img
                        src={require('./images/feature/technology.webp')}
                        alt="business_Icone"
                        className="thumb"
                      />
                      <div className="goal-wrapper">
                        <h6 className="title">Latest Technology</h6>
                        <p className="disc">
                          We use latest and best match technology for our client's
                          projects to make It high quality product.
                        </p>
                      </div>
                    </div>
                    <div className="single-goal">
                      <img
                        src={require('./images/feature/support.webp')}
                        alt="business_Icone"
                        className="thumb"
                      />
                      <div className="goal-wrapper">
                        <h6 className="title">Long-Term Support</h6>
                        <p className="disc">
                          We provide long term support on all the Products you built
                          from us. At Any Time, From Any Where.
                        </p>
                      </div>
                    </div>
                    <div className="single-goal">
                      <img
                        src={require('./images/feature/outsourcing.webp')}
                        alt="business_Icone"
                        className="thumb"
                      />
                      <div className="goal-wrapper">
                        <h6 className="title">Outsourcing</h6>
                        <p className="disc">
                          Outsource your IT needs to Web-Vision, a trusted partner for
                          efficient and reliable solutions. From web development and
                          design to digital marketing and IT support, we handle it
                          all.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* right area business ENd */}
            </div>
          </div>
        </div>
        {/* business goal area End */}
        {/* rts-counter up area start */}
        <div className="rts-counter-up-area rts-section-gap counter-bg pl_sm--15">
          <div className="container">
            <div className="row">
              {/* counter up area */}
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="single-counter">
                  <img
                    src={require('./images/counterup/icon/01.png')}
                    alt="Business_counter"
                  />
                  <div className="counter-details">
                    <h2 className="title">
                      <span className="counter animated fadeInDownBig">
                        <CountUp
                          end={500}
                          duration={5}
                        />
                      </span>
                    </h2>
                    <p className="disc">Projects</p>
                  </div>
                </div>
              </div>
              {/* counter up area */}
              {/* counter up area */}
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="single-counter pl--10 justify-content-center two pl--30">
                  <img
                    src={require('./images/counterup/icon/02.png')}
                    alt="Business_counter"
                  />
                  <div className="counter-details">
                    <h2 className="title rate">
                      <span className="counter animated fadeInDownBig">
                        <CountUp
                          end={99}
                          duration={5}
                        />
                      </span>
                    </h2>
                    <p className="disc">Success Rate</p>
                  </div>
                </div>
              </div>
              {/* counter up area */}
              {/* counter up area */}
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="single-counter pl--10 justify-content-center three pl--50">
                  <img
                    src={require('./images/counterup/icon/03.png')}
                    alt="Business_counter"
                  />
                  <div className="counter-details">
                    <h2 className="title">
                      <span className="counter animated fadeInDownBig">
                        <CountUp
                          end={5}
                          duration={1}
                        />
                      </span>
                    </h2>
                    <p className="disc">Awards</p>
                  </div>
                </div>
              </div>
              {/* counter up area */}
              {/* counter up area */}
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="single-counter pl--10 justify-content-end four">
                  <img
                    src={require('./images/counterup/icon/04.png')}
                    alt="Business_counter"
                  />
                  <div className="counter-details">
                    <h2 className="title happy">
                      <span className="counter animated fadeInDownBig">
                        <CountUp
                          end={450}
                          duration={10}
                        />
                      </span>
                    </h2>
                    <p className="disc">Happy Clients</p>
                  </div>
                </div>
              </div>
              {/* counter up area */}
            </div>
          </div>
        </div>
        {/* rts-counter up area end */}
        {/* start gallery section */}
        <div className="rts-gallery-area rts-section-gap gallery-bg bg_image">
          <div className="container">
            <div className="row">
              <div className="rts-title-area gallery text-start pl_sm--20">
                <p className="pre-title">Successful Campaigns</p>
                <h2 className="title">
                  Past Project Showcases: A Glimpse into Our Accomplishments
                </h2>
              </div>
            </div>
            <div className="row mt--45">
              <div className="col-12">
                <div className="swiper mygallery">
                  <div className="swiper-wrapper gallery">
                    <Swiper
                      // install Swiper modules
                      modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                      spaceBetween={50}
                      slidesPerView={1}
                      navigation
                      pagination={{ clickable: true }}
                      scrollbar={{ draggable: true }}
                      autoplay={{ delay: 4000 }}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                    >
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="row g-5 w-g-100">
                            <div className="col-lg-7">
                              <div className="thumbnail-gallery">
                                <img
                                  src={require('./images/gallery/gallery-3.webp')}
                                  alt="business-images"
                                />
                              </div>
                            </div>
                            <div className="col-lg-5 ">
                              <div className="bg-right-gallery">
                                <div className="icon">
                                  <img src={icon01} alt="Business_map" />
                                </div>
                                <Link to="/project-details3" target="_blank">
                                  <h4 className="title">Geeta Transformer</h4>
                                </Link>
                                <span>Asp.net</span>
                                <p className="disc">
                                  Geeta Transformers is a transformer manufacturing
                                  company with manufacturing units operating in 2
                                  different Cities.
                                </p>
                                <Link to="/project-details3"
                                  className="rts-btn btn-primary"
                                  target="_blank"
                                >
                                  View Project
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="row g-5 w-g-100">
                            <div className="col-lg-7">
                              <div className="thumbnail-gallery">
                                <img
                                  src={require('./images/gallery/gallery-2.webp')}
                                  alt="business-images"
                                />
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="bg-right-gallery">
                                <div className="icon">
                                  <img src={icon01} alt="Business_map" />
                                </div>
                                <Link to="/project-details2" target='_balck'>
                                  <h4 className="title">Swagatam Moblie</h4>
                                </Link>
                                <span>Asp.net</span>
                                <p className="disc">
                                  Swagatam Mobile is a company engaged in the business of
                                  mobile sales and service.In this software, we have ..
                                </p>
                                <Link to="/project-details2"
                                  className="rts-btn btn-primary"
                                  target='_balck'
                                >
                                  View Project
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="row g-5 w-g-100">
                            <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                              <div className="thumbnail-gallery">
                                <img
                                  src={require('./images/gallery/gallery-1.webp')}
                                  alt="business-images"
                                />
                              </div>
                            </div>
                            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                              <div className="bg-right-gallery">
                                <div className="icon">
                                  <img src={icon01} alt="Business_map" />
                                </div>
                                <Link to="/project-details1" target='_blank'>
                                  <h4 className="title">Avani Design</h4>
                                </Link>
                                <span>Asp.net</span>
                                <p className="disc">
                                  Avani Design is a company associated with textile
                                  industries to which we have provided customized software
                                  ..
                                </p>
                                <Link to="/project-details1"
                                  className="rts-btn btn-primary"
                                  target='_blank'
                                >
                                  View Project
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="row g-5 w-g-100">
                            <div className="col-lg-7">
                              <div className="thumbnail-gallery">
                                <img
                                  src={require('./images/gallery/gallery-4.webp')}
                                  alt="business-images"
                                />
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="bg-right-gallery">
                                <div className="icon">
                                  <img src={icon01} alt="Business_map" />
                                </div>
                                <Link to="/project-details4" target='_blank'>
                                  <h4 className="title">Jalpooree</h4>
                                </Link>
                                <span>Nodejs, Flutter</span>
                                <p className="disc">
                                  jalpooree is a pani puri manufacturing company.
                                  jalpooree application is a great example of the entire
                                  eco system...
                                </p>
                                <Link to="/project-details4" target='_blank'
                                  className="rts-btn btn-primary"
                                >
                                  View Project
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="row g-5 w-g-100">
                            <div className="col-lg-7">
                              <div className="thumbnail-gallery">
                                <img
                                  src={require('./images/gallery/gallery-5.webp')}
                                  alt="business-images"
                                />
                              </div>
                            </div>
                            <div className="col-lg-5">
                              <div className="bg-right-gallery">
                                <div className="icon">
                                  {/* <img
                                  src={require('./images/gallery/icon/01.svg')}
                                  alt="Business-gallery"
                                /> */}
                                  <img src={icon01} alt="Business_map" />
                                </div>
                                <Link to="/project-details5" target='_blank'>
                                  <h4 className="title">Koladiya Family</h4>
                                </Link>
                                <span>Asp.Net,c#</span>
                                <p className="disc">
                                  Koladiya Parivar is a social organization, which is
                                  running in the different different city of the state
                                  Gujarat-India...
                                </p>
                                <Link to="/project-details5"
                                  className="rts-btn btn-primary"
                                  target='_blank'
                                >
                                  View Project
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>

                    </Swiper>

                  </div>
                  {/* <div className="swiper-button-next" />
            <div className="swiper-button-prev" />
            <div className="swiper-pagination" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* start gallery section */}
        {/* start trusted client section */}
        <div className="rts-trusted-client rts-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title-area-client text-center">
                  <p className="client-title">Our Trusted Clients</p>
                </div>
              </div>
            </div>
            <div className="row">

              <div className="swiper">
                <div className="swiper-wrapper">
                  <Swiper
                    // install Swiper modules
                    modules={[Autoplay, Navigation, Pagination, Scrollbar]}
                    spaceBetween={50}
                    slidesPerView={5}
                    autoplay={{ delay: 3000 }}
                    breakpoints={{
                      // when window width is >= 575px
                      200: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      420: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      // when window width is >= 575px
                      575: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                      // when window width is >= 1024px
                      1024: {
                        slidesPerView: 5,
                        spaceBetween: 50,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/01.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/02.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/03.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/04.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/05.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/06.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/07.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/08.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/09.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/10.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/11.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/12.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/13.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/14.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/15.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/16.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/17.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/18.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/19.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/20.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/21.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/22.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/23.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/25.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide">
                        <div className="client-wrapper-one">
                          <Link className='cursor-auto'>
                            <img
                              src={require('./images/client/iycci.png')}
                              alt="Business_Team_single"
                            />
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>

                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end trusted client section */}

        {/* rts features area start */}
        <div className="rts-feature-area pb--120">
          <div className="container-fluid padding-controler plr--120">
            <div className="row bg-white-feature  pt--80 pb--120">
              <div className="col-xl-6 col-lg-12">
                <div className="feature-left-area">
                  <img src={require('./images/feature/01.webp')} alt="" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-12">
                <div className="rts-title-area feature text-start pl--30 pl_sm--0">
                  <p className="pre-title">Why To Choose Us</p>
                  <h2 className="title feature-title">
                    Why Webvision Stands Out as The Preferred Choice
                  </h2>
                  <p>
                    We bring expertise, reliability, and innovation to the table. With
                    a proven track record of successful projects, we offer
                    comprehensive IT solutions tailored to your needs. our
                    client-centric approach ensures personalized service and timely
                    delivery. Partner with us to stay ahead in the ever-evolving
                    technology landscape and achieve your business goals with
                    confidence.
                  </p>
                </div>
                <div className="row feature-one-wrapper pl--30 mt--40 pl_sm--0">
                  {/* left wrapper start */}
                  <div className="col-lg-6 col-md-6">
                    <div className="single-feature-one">
                      <i className="far">
                        <FaCheck />
                      </i>
                      <p className="details">Expertise</p>
                    </div>
                    <div className="single-feature-one">
                      <i className="far">
                        <FaCheck />
                      </i>
                      <p className="details">Innovation</p>
                    </div>
                  </div>
                  {/* left wrapper end */}
                  <div className="col-lg-6 col-md-6">
                    <div className="single-feature-one">
                      <i className="far">
                        <FaCheck />
                      </i>
                      <p className="details">Customer-Centric Approach</p>
                    </div>
                    <div className="single-feature-one">
                      <i className="far">
                        <FaCheck />
                      </i>
                      <p className="details">Timely Delivery</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* rts features area end */}
        {/* start client feed back section */}
        <div className="rts-client-feedback">
          <div className="container">
            <div className="row">
              {/* start testimonials area */}
              <div className="col-lg-7">
                <div className="rts-title-area reviews text-start pl--30 pt--70">
                  <p className="pre-title">Our Testimonials</p>
                  <h2 className="title">Client’s feedbacks</h2>
                  {/* swiper area start */}
                  <div className="swiper mySwipertestimonial">
                    <div className="swiper-wrapper">
                      <Swiper
                        // install Swiper modules
                        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation
                        // pagination={{ clickable: true }}
                        // scrollbar={{ draggable: true }}
                        autoplay={{ delay: 4000 }}
                      // onSwiper={(swiper) => console.log(swiper)}
                      // onSlideChange={() => console.log('slide change')}
                      >

                        <SwiperSlide>
                          <div className="swiper-slide">
                            <div className="testimonial-inner">
                              <p className="disc text-start">
                                "Choosing Webvision for our website was the best decision.
                                They brilliantly captured our vision, creating an
                                impactful platform that showcases our solar project. Their
                                team's expertise and dedication were evident throughout
                                the process. We're thrilled with the result and highly
                                recommend their exceptional web development services."
                              </p>
                              <div className="testimonial-bottom-one">
                                <div className="thumbnail">
                                  <img
                                    src={require('./images/testimonials/02.png')}
                                    alt="business-testimonials"
                                  />
                                </div>
                                <div className="details">
                                  <Link className='cursor-auto'>
                                    <h5 className="title">Shreekant Iyer</h5>
                                  </Link>
                                  <span>Sarvayogam Group</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="swiper-slide">
                            <div className="testimonial-inner">
                              <p className="disc text-start">
                                Webvision's CRM for Swagatam Mobile transformed our repair
                                shop! Their seamless system simplified our processes,
                                enhanced customer interactions, and improved efficiency.
                                Working with their team was a pleasure, and the results
                                exceeded our expectations. Highly recommended for anyone
                                seeking top-notch CRM solutions!
                              </p>
                              <div className="testimonial-bottom-one">
                                <div className="thumbnail">
                                  <img
                                    src={require('./images/testimonials/03.png')}
                                    alt="business-testimonials"
                                  />
                                </div>
                                <div className="details">
                                  <Link className='cursor-auto'>
                                    <h5 className="title">Dhiraj Nakarani</h5>
                                  </Link>
                                  <span>Swagatam Mobile</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="swiper-slide">
                            <div className="testimonial-inner">
                              <p className="disc text-start">
                                Webvision's CRM for Geeta Transformer was a game-changer!
                                Their customized solution streamlined our manufacturing
                                processes and improved customer relations. Their team's
                                expertise and support were exceptional, making the
                                implementation smooth. Thanks to Webvision, our efficiency
                                skyrocketed. Highly recommend their CRM services!
                              </p>
                              <div className="testimonial-bottom-one">
                                <div className="thumbnail">
                                  <img
                                    src={require('./images/testimonials/04.png')}
                                    alt="business-testimonials"
                                  />
                                </div>
                                <div className="details">
                                  <Link className='cursor-auto'>
                                    <h5 className="title">Bhavesh Harsora</h5>
                                  </Link>
                                  <span>Geeta Transformer</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="swiper-slide">
                            <div className="testimonial-inner">
                              <p className="disc text-start">
                                Webvision truly transformed our online presence with the
                                stunning website for Ornat Dental Clinic. Their team's
                                creativity and attention to detail were outstanding. The
                                website's user-friendly design and engaging content
                                impressed both our patients and staff. Highly recommended
                                for top-notch web development services!
                              </p>
                              <div className="testimonial-bottom-one">
                                <div className="thumbnail">
                                  <img
                                    src={require('./images/testimonials/05.png')}
                                    alt="business-testimonials"
                                  />
                                </div>
                                <div className="details">
                                  <Link className='cursor-auto'>
                                    <h5 className="title">Dr. Bipin Lathiya</h5>
                                  </Link>
                                  <span>Ornate Painless Dental Hospital</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="swiper-slide">
                            <div className="testimonial-inner">
                              <p className="disc text-start">
                                "Webvision's expertise shone through in crafting our
                                impressive website for Lotus Enterprise. Their team's
                                professionalism and seamless execution brought our
                                wholesale business to life online. The website's design
                                and functionality exceeded our expectations, attracting
                                more customers and boosting our sales. Highly recommend
                                Webvision for exceptional web development!"
                              </p>
                              <div className="testimonial-bottom-one">
                                <div className="thumbnail">
                                  <img
                                    src={require('./images/testimonials/06.png')}
                                    alt="business-testimonials"
                                  />
                                </div>
                                <div className="details">
                                  <Link className='cursor-auto'>
                                    <h5 className="title">Pankaj Prajapati</h5>
                                  </Link>
                                  <span> Lotus Enterprise</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="swiper-slide">
                            <div className="testimonial-inner">
                              <p className="disc text-start">
                                "Webvision's exceptional work on our Shree Shubh Laxmi
                                Sales website exceeded all expectations. Their team
                                brilliantly captured our product-based repurchase plan,
                                creating a dynamic platform that resonates with our
                                customers. The website's user-friendly interface and
                                seamless functionality have contributed to our business's
                                success. Highly recommend their top-notch web development
                                services!"
                              </p>
                              <div className="testimonial-bottom-one">
                                <div className="thumbnail">
                                  <img
                                    src={require('./images/testimonials/06.png')}
                                    alt="business-testimonials"
                                  />
                                </div>
                                <div className="details mb-2">
                                  <Link className='cursor-auto'>
                                    <h5 className="title">Pankaj Patel</h5>
                                  </Link>
                                  <span className=''>Shree Shubh Laxmi Sales</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="swiper-slide">
                            <div className="testimonial-inner">
                              <p className="disc text-start">
                                "Webvision's expertise brought brilliance to our Vraj
                                Mathura Impex website. Their team's creative flair and
                                attention to detail perfectly showcased our diamond
                                business. The website's captivating design and smooth
                                navigation impressed our clients, elevating our online
                                presence. Highly recommended for exceptional web
                                development services!"
                              </p>
                              <div className="testimonial-bottom-one">
                                <div className="thumbnail">
                                  <img
                                    src={require('./images/testimonials/07.png')}
                                    alt="business-testimonials"
                                  />
                                </div>
                                <div className="details">
                                  <Link className='cursor-auto'>
                                    <h5 className="title">Shubham Kikani</h5>
                                  </Link>
                                  <span>Vraj Mathura Impex</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>

                    </div>
                    {/* <div className="swiper-button-prev" />
                    <div className="swiper-button-next" /> */}
                  </div>
                  {/* swiper area end */}
                </div>
              </div>
              {/* end testimonials are */}
              {/* images area */}
              <div className="col-lg-5">
                <div className="rts-test-one-image-inner">
                  <img
                    src={require('./images/testimonials/01.webp')}
                    className='mt_lg--65'
                    alt="business_testimobials"
                  />
                </div>
              </div>
              {/* image area end */}
            </div>
          </div>
        </div>
        {/* start client feed back section End */}
        {/* contact area start */}
        <div className="rts-contact-area contact-one">
          <div className="container">
            <div className="row align-items-center g-0">
              <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                <div className="contact-image-one">
                  <img
                    src={require('./images/contact/contact-left.webp')} alt="" />
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="contact-form-area-one">
                  <div className="rts-title-area contact text-start">
                    <p className="pre-title">Make An Appointment</p>
                    <h2 className="title">Contact US</h2>
                  </div>
                  <div id="form-messages" />
                  <form
                    action="https://formsubmit.co/support@webvisioninfotech.com"
                    method="post"
                  >
                    <div className="row">
                      <div className='col-md-6'>
                        <input
                          type="text"
                          name="name"
                          placeholder="Your Name"
                          onChange={obj => pickName(obj.target.value)}
                          required
                        />
                        <small className='me-3'>{nameerror}</small>
                      </div>
                      <div className='col-md-6'>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          onChange={obj => pickEmail(obj.target.value)}
                          required
                        />
                        <small >{emaileeerror}</small>
                      </div>

                      <div className='col-md-6'>
                        <input
                          type="number"
                          name="Mobile"
                          placeholder="Mobile Number"
                          required
                          onChange={obj => pickMobile(obj.target.value)}
                        />
                        <small>{mobileeerror}</small>
                      </div>
                      
                      <div className='col-md-6'>
                        <input
                          type="text"
                          name="subject"
                          placeholder="Your Subject"
                          required
                          onChange={obj => pickSubject(obj.target.value)}
                        />
                      
                          <small>{subjecterror}</small>
                       
                      </div>
                    </div>

                    <textarea
                      placeholder="Type Your Message"
                      name="message"
                      defaultValue={""}
                      onChange={obj => pickMessage(obj.target.value)}
                      required
                      className='mt-4'
                    />
                    <div className=''>
                      <small>{messageeerror}</small>
                    </div>
                    <button onClick={save} type="submit" className="rts-btn btn-primary">
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* contact area end */}
        {/* map area start */}
        <div className="rts-map-area bg-light-white">
          <div className="container-fluid">
            <div className="row align-items-center" style={{ alignItems: 'center' }}>
              <div className="col-lg-6">

                <div className="mapdetails-inner-one">
                  <div className="left-area single-wized">
                    <h5 className="title">Get in touch</h5>
                    <div className="details">
                      <p>Work and general inquiries</p>
                      <a className="number" href="tel:+918980384148">
                        +91 8980384148
                      </a>
                      <p className="time-header">Assistance hours</p>
                      <p className="time">
                       Monday – Friday <br /> 9:30 AM to 6:30 PM IST
                      </p>
                       {/* <p className="headoffice">Assistance hours:</p>
                      <p className="office">
                        Monday – Friday <br /> 9:30 AM to 6:30 PM IST
                      </p> */}
                    </div>
                  </div>
                  <div className="right-area single-wized">
                    <div className="details">
                      <p className="headoffice">Head Office</p>
                      <p className="office">
                      403-The Galleria Business hub-2, Mahavir chowk, Yogi chowk 
                      </p>
                    </div>
                    <div className="details">
                      <p className="headoffice">Branch Office</p>
                      <p className="office">
                      Allmandring-1, 20-C, 24 Vaihingen, 70569, Stuttgart- Germany
                      </p>
                    </div>
                  </div>
                  {/* <div className="right-area single-wized">
                    <div className="details">
                      <p className="headoffice">Assistance hours:</p>
                      <p className="office">
                        Monday – Friday <br /> 9:30 AM to 6:30 PM IST
                      </p>
                    </div>
                  </div> */}
                </div>

              </div>
              <div className="col-lg-6">

                <div id="map-container"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default Home