
const Unity = () => {

    return(
        <>
                        
            {/* start breadcrumb area */}
            <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">Unity Fortune Website</h1>
                            <p className="sub-title font-static-white">
                                Embark on unforgettable journeys and explore the world through our captivating traveling website, <br/>
                                where every destination is a story waiting to be written.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row"  style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/unity-fortune.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About Unity Fortune Website</span>
                                    <h3 className="title">Unity Fortune Website</h3>
                                    <p className="disc">
                                    Dive into a world of wanderlust and exploration with our captivating traveling website, designed to ignite your passion for adventure. Here, travel is more than just a journey; it's a gateway to immersive experiences and unforgettable memories. Explore a diverse range of destinations, from hidden gems to iconic landmarks, all curated to inspire every type of traveler. Delve into rich travel guides, insightful itineraries, and firsthand accounts from globetrotters who have traversed the paths you dream of. Whether you're seeking serene escapes, cultural immersion, or thrilling escapades, our platform is your compass to discover, plan, and embark on journeys that redefine the art of exploration.

                                    </p>
                                    <p className="disc">
                                    Indulge your curiosity with our comprehensive range of resources, where travel becomes a personalized quest. From practical tips on packing and navigating unfamiliar territories to captivating narratives that transport you to far-off lands, our traveling website is a treasure trove of inspiration. Uncover the essence of each destination through vibrant visuals, historical context, and local insights, allowing you to engage with cultures and landscapes like never before. Join our global community of adventurers and seekers, and let the pages of our website guide you towards the enriching experiences that await, transforming your wanderlust into a reality of breathtaking discoveries.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">Unity Fortune Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                The traveling website offers curated destination guides, travel itineraries, and 
                                immersive stories to inspire and assist travelers in their journey.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details appointment-img">
                                <img
                                    src={require('./images/gallery/inner-image/unity-fortune-2.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Destination Guides:</h5>
                                    <p className="disc">
                                     Provide comprehensive guides for various destinations, offering insights into attractions, culture, history, and practical information.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Travel Itineraries: </h5>
                                    <p className="disc">
                                    Create sample travel itineraries for different durations and interests, helping users plan their trips effectively.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Travel Stories:</h5>
                                    <p className="disc">
                                     Share personal travel experiences and narratives from travelers who have explored different places around the world.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title"> Photography and Videos:</h5>
                                    <p className="disc">
                                     Showcase high-quality photos and videos that capture the essence and beauty of various travel destinations.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Travel Tips: </h5>
                                    <p className="disc">
                                     Offer practical tips on packing, transportation, safety, local customs, and other essential travel advice.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title"> Solo Travel Resources:</h5>
                                    <p className="disc">
                                     Offer resources and tips specifically tailored for solo travelers, addressing safety, planning, and experiences.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Cultural Insights:
                                    </h5>
                                    <p className="disc">
                                     Include information about local customs, traditions, festivals, and etiquette to help travelers respect and engage with the local culture.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Weather and Climate Information: 
                                    </h5>
                                    <p className="disc">
                                    Provide weather forecasts and climate information for different destinations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img
                                    src={require('./images/gallery/inner-image/banner/unity-fortune.webp')}
                                    alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* project details image area end */}
        </>
    )
 }
 export default Unity