import { FaCheck } from "react-icons/fa"
import { Link } from "react-router-dom"
import { HiOutlineArrowRight } from "react-icons/hi"
import { useState } from "react"
const Cms = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleCloseSidebar = () => {
        setIsSidebarOpen(false);
      };
    
    const handleMenuItemClick = () => {
        handleCloseSidebar(); // Close the sidebar when a menu item is clicked
        window.scrollTo(0, 0); // Scroll to the top of the page
      };
    return (
        <>
            <>
                {/* start breadcrumb area */}
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">CMS Development</h1>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="bread-tag">
                                    <Link to="/" className="active">Home</Link>
                                    <span> / </span>
                                    <Link to="/service" className="active">Service</Link>
                                    <span> / </span>
                                    <Link>
                                        CMS Development
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* start service details area */}
                <div className="rts-service-details-area rts-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                                {/* service details left area start */}
                                <div className="service-detials-step-1">
                                    <div className="thumbnail">
                                        <img
                                            src={require('./images/service/inner-imges/CMS_development.webp')}
                                            alt="business-area"
                                        />
                                    </div>
                                    <h4 className="title">About CMS Development</h4>
                                    <p className="disc">
                                        Welcome to our CMS Development services, where we revolutionize
                                        your website management experience.
                                    </p>
                                    <p className="disc">
                                        As experts in Content Management System (CMS) solutions, we offer
                                        bespoke platforms tailored to your business's precise
                                        requirements. Our team ensures that you have full control over
                                        your website's content, enabling seamless updates and
                                        customization without any technical hassles.
                                    </p>
                                    <p className="disc">
                                        With our user-friendly CMS, you can effortlessly create, modify,
                                        and publish content, empowering your online presence like never
                                        before. Embrace the freedom to innovate, stay agile, and captivate
                                        your audience with our cutting-edge CMS Development services,
                                        driving your online success to new heights."
                                    </p>
                                    <p className="disc">
                                        We can provide easy to use and according to client requirements
                                        content management and solutions for the site owner to manage
                                        their data, allow them to update the content at their site and add
                                        graphics with a few clicks of mouse.
                                    </p>
                                    <p className="disc">
                                        Web content management system or CMS would make it easy for our
                                        clients to add or delete content, edit or move date from their
                                        website easily. The clients can do it themselves and allow anyone
                                        to do it for them by giving them permission. Anyone can add blog
                                        posts, tweak headlines and update latest products without needing
                                        an expert. It’s all in the hands of client.
                                    </p>
                                    <p className="disc">
                                        Other than regular website maintenance, we work on enhancing
                                        existing features, addition of content, bug fixing and deliver
                                        constant technical support.Not only that, we advise you on the
                                        changes required to the existing website design, identify the
                                        improvement areas of the site and determine the reasons your site
                                        does not feature on priority in the search result pages in world
                                    </p>
                                </div>
                                <div className="row g-5 mt--30 mb--40">
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card" style={{height:'360px'}}>
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/01.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">
                                                    Backend Development and Database Integration
                                                </h6>
                                                <p className="disc">
                                                    Building the functional core of the CMS that handles data
                                                    processing and storage, integrating it with a reliable
                                                    database system, and creating APIs for seamless
                                                    communication between the front-end and backend.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card" style={{height:'360px'}}>
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/02.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">Content Organization and Taxonomy</h6>
                                                <p className="disc">
                                                    Creating a logical and hierarchical structure for content
                                                    storage, implementing categories and tags for effective
                                                    content organization, and enabling quick search and
                                                    retrieval of information.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                </div>
                                {/* service details left area end */}
                                <div className="service-detials-step-3 mt--70 mt_md--50">
                                    <h4 className="title">
                                        Our Exhaustive list of CMS Development Services Include :
                                    </h4>
                                    <p className="disc">
                                        Catalysts for change before fully tested markets are maintain
                                        wireless scenarios after intermandated applications predominate
                                        revolutionary.
                                    </p>
                                    <div className="row g-5 align-items-center">
                                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <ul className="list-unstyled benefits-two__points ml-0">
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Multi Level Marketing Website</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p> CMS Development Tools For B2C and B2B Portals</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p> Web Content Development and Management Services</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                            <ul className="list-unstyled benefits-two__points ml-0">
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p> School or Institute Management System</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>
                                                            {" "}
                                                            Enterprise Content Development and Management Services
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p> Diamond Stone Management and Marketing Website</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*rts blog wizered area */}
                            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                                {/* single wizered start */}
                                <div className="rts-single-wized Categories service">
                                    <div className="wized-header">
                                        <h5 className="title">Our Services</h5>
                                    </div>
                                    <div className="wized-body">
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/web-design" onClick={handleMenuItemClick} >
                                                    Web Designing{" "}
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/ui-ux" onClick={handleMenuItemClick}>
                                                    UI-UX Designing  <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/graphics" onClick={handleMenuItemClick}>
                                                    Graphics Designing
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/logo" onClick={handleMenuItemClick}>
                                                    Logo Designing <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/web-development" onClick={handleMenuItemClick}>
                                                    Web Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/app-development" onClick={handleMenuItemClick}>
                                                    App Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/sass" onClick={handleMenuItemClick}>
                                                    SAAS Development<i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/cms" className="active" onClick={handleMenuItemClick}>
                                                    CMS Development <i className="far active"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/payment-gateway" onClick={handleMenuItemClick}>
                                                    Payment Gateway Integration{" "}
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris End */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/digital" onClick={handleMenuItemClick}>
                                                    Digital Marketing
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/branding" onClick={handleMenuItemClick}>
                                                    Branding
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                    </div>
                                </div>

                                <div className="rts-single-wized contact service">
                                    <div className="wized-header">
                                        <Link to="/">
                                            <img
                                                src={require('./images/logo/white-logo.png')}
                                                alt="Business_logo"
                                            />
                                        </Link>
                                    </div>
                                    <div className="wized-body">
                                        <h5 className="title" >Need Help? We Are Here To Help You</h5>
                                        <Link to="/contact" target="_blank" className="rts-btn btn-primary">
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                                {/* single wizered End */}
                            </div>
                            {/* rts- blog wizered end area */}
                        </div>
                    </div>
                </div>
            </>

        </>
    )
}
export default Cms;