import { FaCheck } from "react-icons/fa"
import { Link } from "react-router-dom"
import { HiOutlineArrowRight } from "react-icons/hi"
import { useState } from "react"

const Logo = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

const handleMenuItemClick = () => {
    handleCloseSidebar(); // Close the sidebar when a menu item is clicked
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
    return (
        <>
            <>
                {/* start breadcrumb area */}
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">Logo Designing</h1>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="bread-tag">
                                    <Link to="/" className="active">Home</Link>
                                    <span> / </span>
                                    <Link to="/service" className="active">Service</Link>
                                    <span> / </span>
                                    <Link>
                                        Logo Designing
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* start service details area */}
                <div className="rts-service-details-area rts-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                                {/* service details left area start */}
                                <div className="service-detials-step-1">
                                    <div className="thumbnail">
                                        <img
                                            src={require('./images/service/inner-imges/logo_designing.webp')}
                                            alt="business-area"
                                        />
                                    </div>
                                    <h4 className="title">About Logo Designing</h4>
                                    <p className="disc">
                                        Unlock the artistry of your brand with our bespoke logo designing.
                                        our team of visionary designers will craft a one-of-a-kind emblem
                                        that encapsulates your brand's essence and resonates with your
                                        audience on a profound level.
                                    </p>
                                    <p className="disc">
                                        Beyond a mere symbol, our logos become a storytelling masterpiece,
                                        representing the soul of your business in a visually compelling
                                        manner. Through meticulous research and imaginative flair, we
                                        breathe life into your brand, creating a logo that becomes an
                                        emblem of distinction in your industry.
                                    </p>
                                    <p className="disc">
                                        Ignite a powerful connection with your customers and stand tall
                                        among competitors with our unparalleled Logo Designing, where
                                        creativity and strategy intertwine to ignite a brand revolution. I
                                    </p>
                                </div>
                                <div className="row g-5 mt--30 mb--40">
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/02.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">Logo &amp; Branding</h6>
                                                <p className="disc">
                                                    The face of your brand is your logo! You can get the
                                                    hard-hitting logo design ideas from our team of talented
                                                    logo designers. With our innovative logo and branding
                                                    services, you can easily stand out from the competition.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                   src={require('./images/service/icon/03.webp')}
                                                    alt="icon"
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">Web UI Design</h6>
                                                <p className="disc">
                                                    Increase your brand’s online presence with our ROI-driven
                                                    Web UI Design and development service across the globe.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                </div>
                                {/* service details left area end */}
                                <div className="service-detials-step-3 mt--70 mt_md--50">
                                    <div className="row g-5 align-items-center">
                                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="thumbnail sm-thumb-service">
                                                <img
                                                    src={require('./images/service/sm-01.jpg')}
                                                    alt="Service" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                            <h4 className="title">Our Logo Designing Services Include:</h4>
                                            <ul className="list-unstyled benefits-two__points ml-0">
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Web Design Agency</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Web UI Design</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Logo &amp; Branding</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*rts blog wizered area */}
                            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                                {/* single wizered start */}
                                <div className="rts-single-wized Categories service">
                                    <div className="wized-header">
                                        <h5 className="title">Our Services</h5>
                                    </div>
                                    <div className="wized-body">
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/web-design" onClick={handleMenuItemClick} >
                                                    Web Designing{" "}
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/ui-ux" onClick={handleMenuItemClick}>
                                                    UI-UX Designing  <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/graphics" onClick={handleMenuItemClick}>
                                                    Graphics Designing
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/logo" className="active" onClick={handleMenuItemClick}>
                                                    Logo Designing <i className="far active"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/web-development" onClick={handleMenuItemClick}>
                                                    Web Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/app-development" onClick={handleMenuItemClick}>
                                                    App Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/sass" onClick={handleMenuItemClick}>
                                                    SAAS Development<i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/cms" onClick={handleMenuItemClick}>
                                                    CMS Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/payment-gateway" onClick={handleMenuItemClick}>
                                                    Payment Gateway Integration{" "}
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris End */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/digital" onClick={handleMenuItemClick}>
                                                    Digital Marketing
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/branding" onClick={handleMenuItemClick}>
                                                    Branding
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                    </div>
                                </div>

                                <div className="rts-single-wized contact service">
                                    <div className="wized-header">
                                        <Link to="/">
                                            <img
                                                src={require('./images/logo/white-logo.png')}
                                                alt="Business_logo"
                                            />
                                        </Link>
                                    </div>
                                    <div className="wized-body">
                                        <h5 className="title">Need Help? We Are Here To Help You</h5>
                                        <Link to="/contact" target="_blank" className="rts-btn btn-primary">
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                                {/* single wizered End */}
                            </div>
                            {/* rts- blog wizered end area */}
                        </div>
                    </div>
                </div>
                {/* End service details area */}
            </>

        </>
    )
}
export default Logo;