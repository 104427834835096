const Akplus = () =>{
    return(
       <>
                      {/* start breadcrumb area */}
                      <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">AKPlus Website</h1>
                            <p className="sub-title font-static-white">
                            Experience peace of mind and comprehensive coverage with our health insurance <br/>
                            website, where your well-being is our top priority.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row"  style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/ak-plus-2.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About AKPlus Website</span>
                                    <h3 className="title">AKPlus Website</h3>
                                    <p className="disc">
                                    Discover peace of mind and comprehensive coverage through our dedicated health insurance website, where your well-being is paramount. With a deep understanding of the value of good health, our platform offers a range of tailored health insurance solutions designed to safeguard your future. Whether you're an individual seeking personalized coverage or a family looking for dependable protection, our website serves as a gateway to explore diverse plans, compare benefits, and make informed decisions that prioritize both your health and financial stability.
                                      </p>
                                    <p className="disc">
                                    Navigate our intuitive interface to access clear policy details, network providers, and streamlined claim processes. Our health insurance website empowers you with expert insights and knowledge, helping you choose the optimal plan that aligns seamlessly with your lifestyle and ensures preparedness for any unforeseen medical expenses. Step into a realm where your health takes center stage, and let us guide you through the intricacies of health insurance, equipping you with the tools you need to secure a healthier and more secure future.
                                     </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">AkPlus Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                The health insurance website offers comprehensive plans, policy comparisons, provider networks, and transparent claim processes for a seamless and informed healthcare experience.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details appointment-img">
                                <img
                                    src={require('./images/gallery/inner-image/ak-plus-3.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Plan Comparison:</h5>
                                    <p className="disc">
                                     Provide a tool to compare different health insurance plans based on coverage, benefits, premiums, and deductibles.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Policy Details:  </h5>
                                    <p className="disc">
                                    Display clear and comprehensive policy details, including coverage limits, exclusions, and inclusions.
 </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Coverage Types:</h5>
                                    <p className="disc">
                                     Offer a range of coverage types, including individual, family, group, and specialized plans.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Provider Network: </h5>
                                    <p className="disc">
                                    Provide information about the network of healthcare providers, hospitals, and clinics that are covered by the insurance plans.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Claim Process:</h5>
                                    <p className="disc">
                                     Explain the step-by-step process of filing claims, including required documentation and timelines.

                                    </p>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Online Enrollment:</h5>
                                    <p className="disc">
                                     Enable users to enroll in health insurance plans online, streamlining the enrollment process.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Customer Support: </h5>
                                    <p className="disc">
                                    Offer a dedicated customer support channel for inquiries, assistance with claims, and policy-related questions.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Healthcare Resource Center: 
                                    </h5>
                                    <p className="disc">
                                    Provide educational resources about health and wellness, covering topics like managing chronic conditions and staying healthy.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Renewal Reminders: 
                                    </h5>
                                    <p className="disc">
                                     Send automated reminders to users for policy renewal, ensuring continuous coverage.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Emergency Assistance:
                                    </h5>
                                    <p className="disc">
                                     Detail emergency services coverage, including ambulance services and emergency room visits.
                                    </p>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img
                                    src={require('./images/gallery/inner-image/banner/ak-plus.webp')}
                                    alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* project details image area end */}
       </>
    )
}
export default Akplus