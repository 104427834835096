
const Volfon = () => {

    return(
        <>
              
            {/* start breadcrumb area */}
            <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">Volfon</h1>
                            <p className="sub-title font-static-white">
                            Explore a world of convenience and choice with Volfon's online shopping website, where you can discover <br/>an extensive range of products tailored to your needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row"  style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/volfon-1.webp')}
                                    alt="volfon"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About Volfon</span>
                                    <h3 className="title">Volfon</h3>
                                    <p className="disc">
                                    Step into a realm of convenience and choice through Volfon's online shopping platform, where an extensive array of products is at your fingertips. With a focus on enhancing your shopping experience, our website caters to diverse needs, offering categories ranging from fashion and electronics to home essentials and more. Seamlessly navigate through our intuitive interface, complete with secure payment options and reliable delivery services, ensuring that your shopping journey is effortless and satisfying.

                                    </p>
                                    <p className="disc">
                                    Embrace a shopping revolution with Volfon's online platform that goes beyond transactional exchanges. Engage with an array of user reviews, detailed product information, and personalized recommendations, empowering you to make informed decisions that align with your preferences. Immerse yourself in a vibrant community of discerning shoppers who value authenticity and convenience, all while enjoying the luxury of doorstep delivery. With a commitment to excellence, Volfon's online shopping website redefines retail, granting you the freedom to explore, choose, and embrace a curated world of products from the comfort of your own space.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">Volfon Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                    The Volfon online shopping website offers a user-friendly platform, diverse product range, secure transactions, and efficient customer support for a seamless shopping experience.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details appointment-img">
                                <img
                                    src={require('./images/gallery/inner-image/volfon-3.webp')}
                                    alt="volfon"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Product Categories:</h5>
                                    <p className="disc">
                                     Offer a diverse range of categories, from fashion and electronics to home goods and more, catering to a variety of customer preferences.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Search and Filters:</h5>
                                    <p className="disc">
                                     Provide robust search and filtering options to help customers find products quickly and efficiently.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Detailed Product Pages:</h5>
                                    <p className="disc">
                                     Display comprehensive product information, including images, descriptions, specifications, and customer reviews.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title"> User Reviews and Ratings:</h5>
                                    <p className="disc">
                                     Allow customers to share their feedback and ratings for products, enhancing transparency and aiding purchase decisions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Wishlist and Favorites: </h5>
                                    <p className="disc">
                                    Enable users to save products they're interested in for future reference or purchase.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title"> Shopping Cart:</h5>
                                    <p className="disc">
                                   Provide a user-friendly shopping cart interface where customers can review their selected items before checkout.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Return and Refund Policies:
                                    </h5>
                                    <p className="disc">
                                    Clearly outline the procedures for returns, exchanges, and refunds to enhance customer confidence.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Customer Accounts:
                                    </h5>
                                    <p className="disc">
                                     Provide users with accounts where they can manage their personal information, shipping addresses, and order history.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img
                                    // src="assets/images/gallery/inner-image/" 
                                    src={require('./images/gallery/inner-image/banner/volfon.webp')}
                                    alt="volfon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* project details image area end */}
        </>
    )
 }
 export default Volfon