
const Iycci = () => {

    return (
        <>

            {/* start breadcrumb area */}
            <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">IYCCI Website</h1>
                            <p className="sub-title font-static-white">
                                The Indian Youth Chamber of Commerce and Industry is a dynamic platform empowering <br/> 
                                young entrepreneurs  and professionals to thrive in India's business <br/> 
                                landscape through networking, skill development, and advocacy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row"  style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/iycci-3.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About IYCCI</span>
                                    <h3 className="title">IYCCI</h3>
                                    <p className="disc">
                                    Indian Youth Chamber of Commerce and Industry (IYCCI) is an organization seeded with the idea of establishing a platform for the youth to ‘actualise their potential’ and become ‘global citizens’. Youth Empowerment is an agenda of immense discussions and contemplation worldwide which has been on the top priority of most of government plans, programs and policies. The emerging contours of the new world of work in ongoing global technological advancements are rapidly becoming a lived reality for millions of employees and employers around the world. IYCCI operates as a platform to bridge the gap between acquired skills and skills in demand through its various ‘industry-academia’ engagement programs. With primary focus on youth empowerment
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">IYCCI Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                The Indian Youth Chamber of Commerce and Industry website empowers young entrepreneurs with networking, skill development, advocacy, and industry insights.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details appointment-img">
                                <img
                                    src={require('./images/gallery/inner-image/iycci-2.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Networking Opportunities:</h5>
                                    <p className="disc">
                                     Provide a platform for young entrepreneurs and professionals to connect, collaborate, and build meaningful relationships with peers and industry experts.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Skill Development:</h5>
                                    <p className="disc">
                                     Offer workshops, training programs, webinars, and seminars to enhance the skills and knowledge of members in various business-related areas.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Business Advocacy:</h5>
                                    <p className="disc">
                                     Advocate for the interests and concerns of young entrepreneurs and professionals on policy matters, regulations, and business environment improvements.
                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title"> Membership Directory:</h5>
                                    <p className="disc">
                                    Maintain a directory of members, making it easier for them to connect and collaborate.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner">
                                <div className="para">
                                    <h5 className="title">Business Resources:</h5>
                                    <p className="disc">
                                     Offer access to a variety of resources, including business guides, market insights, and research reports.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">Entrepreneurial Support: </h5>
                                    <p className="disc">
                                    Provide guidance, mentorship, and support for startups and aspiring entrepreneurs in their business ventures.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Events and Conferences:
                                    </h5>
                                    <p className="disc">
                                     Organize networking events, conferences, and forums to facilitate learning, idea exchange, and exposure to industry trends.

                                    </p>
                                </div>
                                <div className="para">
                                    <h5 className="title">
                                    Start-Up Incubation:
                                    </h5>
                                    <p className="disc">
                                     Provide support for start-ups through incubation programs, mentorship, and access to resources.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img
                                    src={require('./images/gallery/inner-image/banner/iycci.webp')}
                                    alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* project details image area end */}
        </>
    )
}
export default Iycci