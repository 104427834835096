import { Link } from 'react-router-dom';

const Projectfour = () => {
  return (
    <>
      <>
        {/* start breadcrumb area */}
        <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                <h1 className="title">Portfolio Details</h1>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="bread-tag">
                  <Link to="/">Home</Link>
                  <span> / </span>
                  <Link className="active">
                    Portfolio Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end breadcrumb area */}
        {/* project details image area */}
        <div className="rts-project-details-area rts-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="big-bg-porduct-details">
                  <img
                    src={require('./images/product/bg-lg-05.png')}
                    alt="Business_Finbiz"
                  />
                  <div className="project-info">
                    <div className="info-head">
                      <h5 className="title">Project Information</h5>
                    </div>
                    <div className="info-body">
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-user" />
                        </div>
                        <div className="info-details">
                          <span>Project Name:</span>
                          <h6 className="name">Jalpooree</h6>
                        </div>
                      </div>
                      {/* end single info */}
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-user" />
                        </div>
                        <div className="info-details">
                          <span>Clients:</span>
                          <h6 className="name">Alpesh Kathiria</h6>
                        </div>
                      </div>
                      {/* end single info */}
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-layer-group" />
                        </div>
                        <div className="info-details">
                          <span>Category:</span>
                          <h6 className="name">Manufacture’s Of Pani Pooree</h6>
                        </div>
                      </div>
                      {/* end single info */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt--70 mb--50">
              <div className="col-12">
                <div className="product-details-main-inner">
                  <span>Growth Solution</span>
                  <h3 className="title">Our Business Growth</h3>
                  <p className="disc">
                    We have developed a mobile application for the entire system of
                    jalpooree in which we have developed a whole channel system like
                    creation of new go down by admin, creation of distributor through
                    go down, creation of sales partner through distributor, creation
                    of bakery through sales partner.
                  </p>
                  <p className="italic">
                    “jalpooree is a pani puri manufacturing company. We have made a
                    revolutionary contribution to the growth of jalpooree by taking
                    the entire manufacturing and supply process online.”
                  </p>
                </div>
              </div>
            </div>
            <div className="row g-5 mb--60">
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-04.png')} alt="" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-05.png')} alt="" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-06.png')} alt="" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-12">
                <h3 className="title mb--0">The Challenge Of Project</h3>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pr--80">
                <div className="single-project-details-challenge">
                  <p className="details">
                    Along with this, goods can be exchanged in each panel, bakery can
                    order goods, sales partner can upload bakery location and bakery
                    photos, stock transfer bill and any payment system including part
                    payment, full payment, payment details like Features are also
                    included.
                  </p>
                  <p className="details">
                    In short, jalpooree application is a great example of the entire
                    eco system of a manufacturing company to deliver its products to
                    retail.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* project details image area end */}
      </>

    </>
  )
}
export default Projectfour;