import { Link } from 'react-router-dom';
import { useState } from 'react'; // Import useState
const Workshow = () => {
  // const showForm = () => {

  //   let form = document.getElementById('forms')
  //   form.style.display = 'block'
  // }
  const [showButton, setShowButton] = useState(true); // Initialize showButton state

  const showForm = () => {
    let form = document.getElementById('forms');
    form.style.display = 'block';
    setShowButton(false); // Set showButton to false to hide the button
  }
  return (
    <>
      <>
        {/* start breadcrumb area */}
        <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                <h1 className="title">Our Work Showcases</h1>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="bread-tag">
                  <Link to="/" className="active">Home</Link>
                  <span> / </span>
                  <Link to="/service" className="active">Service</Link>
                  <span> / </span>
                  <Link to="/workshow">
                    Work Showcases
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end breadcrumb area */}
        {/* start our-team details area */}
        {/* rts founder two area */}
        <div className="rts-work-area rts-section-gapTop rts-section-gapBottom team-two">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="rts-title-area team text-center">
                  <p className="pre-title">Company Work Showcase</p>
                  <h2 className="title">Our Accomplishments</h2>
                  <p>
                    Webvision takes pride to have successfully completed hundreds of
                    projects leveraging varying technology expertise across
                    industries. Have a glimpse at them here!
                  </p>
                </div>
              </div>
            </div>

            <div className="row g-5 mt--20 mt_md--30 mt_sm--0">
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                {/* single team inner */}
                <div className="team-inner-two inner">
                  <div className="thumbnail webvision-portfolio-image">
                    <img
                      src={require('./images/work-showcase/T3WebWorld.webp')}
                      alt="T3WebWorld"
                    />
                    <div className="view-content">
                      <Link to="/t3webworld" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                    </div>
                  </div>
                  {/* Acquaintance area */}
                  <div className="acquaintance-area">
                    <div className="header">
                      <Link to="/t3webworld" target='_blank'><h6 className="title">T3 WebWorld</h6> </Link>
                      <span />
                    </div>
                  </div>
                  {/* Acquaintance area */}
                </div>
                {/* single team inner End */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                {/* single team inner */}
                <div className="team-inner-two inner">
                  <div className="thumbnail webvision-portfolio-image">
                    <img
                      src={require('./images/work-showcase/volfon.webp')}
                      alt="T3Supportsystem"
                    />
                    <div className="view-content">
                      <Link to="/volfon" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                    </div>
                  </div>
                  {/* Acquaintance area */}
                  <div className="acquaintance-area">
                    <div className="header">
                      <Link to="/volfon" target='_blank'><h6 className="title">Volfon</h6></Link>
                      <span />
                    </div>
                  </div>
                  {/* Acquaintance area */}
                </div>
                {/* single team inner End */}
              </div>
              {/* single team End */}
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                {/* single team inner */}
                <div className="team-inner-two inner">
                  <div className="thumbnail webvision-portfolio-image">
                    <img
                      src={require('./images/work-showcase/unity_fortune.webp')}
                      alt="T3Supportsystem"
                    />
                    <div className="view-content">
                      <Link to="/unity-fortune" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                    </div>
                  </div>
                  {/* Acquaintance area */}
                  <div className="acquaintance-area">
                    <div className="header">
                      <Link to="/unity-fortune" target='_blank'><h6 className="title">Unity fortune</h6></Link>
                      <span />
                    </div>
                  </div>
                  {/* Acquaintance area */}
                </div>
                {/* single team inner End */}
              </div>
              {/* single team End */}

              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                {/* single team inner */}
                <div className="team-inner-two inner">
                  <div className="thumbnail webvision-portfolio-image">
                    <img
                      src={require('./images/work-showcase/vmi_diamonds.webp')}
                      alt="T3Supportsystem"
                    />
                    <div className="view-content">
                      <Link to="/vmi-diamond" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                    </div>
                  </div>
                  {/* Acquaintance area */}
                  <div className="acquaintance-area">
                    <div className="header">
                      <Link to="/vmi-diamond" target='_blank'><h6 className="title">VMI Diamond </h6></Link>
                      <span />
                    </div>
                  </div>
                  {/* Acquaintance area */}
                </div>
                {/* single team inner End */}
              </div>
              {/* single team End */}
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                {/* single team inner */}
                <div className="team-inner-two inner">
                  <div className="thumbnail webvision-portfolio-image">
                    <img
                      src={require('./images/work-showcase/hd_enterprize.webp')}
                      alt="T3Supportsystem"
                    />
                    <div className="view-content">
                      <Link to="/hd-enterprise" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                    </div>
                  </div>
                  {/* Acquaintance area */}
                  <div className="acquaintance-area">
                    <div className="header">
                      <Link to="/hd-enterprise" target='_blank'><h6 className="title">HD Enterprise </h6></Link>
                      <span />
                    </div>
                  </div>
                  {/* Acquaintance area */}
                </div>
                {/* single team inner End */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                {/* single team inner */}
                <div className="team-inner-two inner">
                  <div className="thumbnail webvision-portfolio-image">
                    <img
                      src={require('./images/work-showcase/education.webp')}
                      alt="T3Supportsystem"
                    />
                    <div className="view-content">
                      <Link to="/eduction" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                    </div>
                  </div>
                  {/* Acquaintance area */}
                  <div className="acquaintance-area">
                    <div className="header">
                      <Link to="/eduction" target='_blank'><h6 className="title">Education</h6></Link>
                      <span />
                    </div>
                  </div>
                  {/* Acquaintance area */}
                </div>
                {/* single team inner End */}
              </div>
              {/* single team End */}

              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                {/* single team inner */}
                <div className="team-inner-two inner">
                  <div className="thumbnail webvision-portfolio-image">
                    <img
                      src={require('./images/work-showcase/iycci.webp')}
                      alt="T3Supportsystem"
                    />
                    <div className="view-content">
                      <Link to="/iycci" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                    </div>
                  </div>
                  {/* Acquaintance area */}
                  <div className="acquaintance-area">
                    <div className="header">
                      <Link to="/iycci" target='_blank'><h6 className="title">IYCCI</h6></Link>
                      <span />
                    </div>
                  </div>
                  {/* Acquaintance area */}
                </div>
                {/* single team inner End */}
              </div>
              {/* single team End */}
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">

                <div className="team-inner-two inner">
                  <div className="thumbnail webvision-portfolio-image">
                    <Link to="/e-commerce" target='_blank'>
                    <img
                      src={require('./images/work-showcase/e-commerce.webp')}
                      alt="e-commerce"
                    /></Link>
                    <div className="view-content">
                      <Link to="/e-commerce" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                    </div>
                  </div>

                  <div className="acquaintance-area">
                    <div className="header">
                      <Link to="/e-commerce" target='_blank'> <h6 className="title">E-Commerce</h6></Link>
                      <span />
                    </div>
                  </div>

                </div>

              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                {/* single team inner */}
                <div className="team-inner-two inner">
                  <div className="thumbnail webvision-portfolio-image">
                    <img
                      src={require('./images/work-showcase/ornate_clinic.webp')}
                      alt="T3Supportsystem"
                    />
                    <div className="view-content">
                      <Link to="/ornate" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                    </div>
                  </div>
                  {/* Acquaintance area */}
                  <div className="acquaintance-area">
                    <div className="header">
                      <Link to="/ornate" target='_blank'><h6 className="title">Ornate </h6></Link>
                      <span />
                    </div>
                  </div>
                  {/* Acquaintance area */}
                </div>
                {/* single team inner End */}
              </div>
              {/* single team End */}


              <div className="cart-price text-center">
                {/* Only display the button if showButton is true */}
                {showButton && <button className="view-more-work" onClick={showForm}>Read More</button>}
              </div>


              <div id="forms" style={{ display: 'none' }}>
                <div className='row'>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    {/* single team inner */}
                    <div className="team-inner-two inner">
                      <div className="thumbnail webvision-portfolio-image">
                        <img
                          src={require('./images/work-showcase/pizza.webp')}
                          alt="T3Supportsystem"
                        />
                        <div className="view-content">
                          <Link to="/pizza" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                        </div>
                      </div>
                      {/* Acquaintance area */}
                      <div className="acquaintance-area">
                        <div className="header">
                          <Link to="/pizza" target='_blank'><h6 className="title">Pizza</h6></Link>
                          <span />
                        </div>
                      </div>
                      {/* Acquaintance area */}
                    </div>
                    {/* single team inner End */}
                  </div>
                  {/* single team End */}
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">

                    <div className="team-inner-two inner">
                      <div className="thumbnail webvision-portfolio-image">
                        <img
                          src={require('./images/work-showcase/akheelam.webp')}
                          alt="akheelam"
                        />
                        <div className="view-content">
                          <Link to="/akheelam" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                        </div>
                      </div>

                      <div className="acquaintance-area">
                        <div className="header">
                          <Link to="/akheelam" target='_blank'><h6 className="title">Akheelam</h6></Link>
                          <span />
                        </div>
                      </div>

                    </div>

                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="team-inner-two inner">
                      <div className="thumbnail webvision-portfolio-image">
                        <img
                          src={require('./images/work-showcase/solar.webp')}
                          alt="solarwebsite"
                        />
                        <div className="view-content">
                          <Link to="/solar" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                        </div>
                      </div>
                      <div className="acquaintance-area">
                        <div className="header">
                          <Link to="/solar" target='_blank'><h6 className="title">Sarvayogam solar </h6></Link>
                          <span />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    {/* single team inner */}
                    <div className="team-inner-two inner">
                      <div className="thumbnail webvision-portfolio-image">
                        <img
                          src={require('./images/work-showcase/construction.webp')}
                          alt="T3Supportsystem"
                        />
                        <div className="view-content">
                          <Link to="/construction" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                        </div>
                      </div>
                      {/* Acquaintance area */}
                      <div className="acquaintance-area">
                        <div className="header">
                          <Link to="/construction" target='_blank'><h6 className="title">Construction </h6></Link>
                          <span />
                        </div>
                      </div>
                      {/* Acquaintance area */}
                    </div>
                    {/* single team inner End */}
                  </div>
                  {/* single team End */}
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    {/* single team inner */}
                    <div className="team-inner-two inner">
                      <div className="thumbnail webvision-portfolio-image">
                        <img
                          src={require('./images/work-showcase/T3Supportsystem.webp')}
                          alt="T3Supportsystem"
                        />
                        <div className="view-content">
                          <Link to="/t3-supportsystem" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                        </div>
                      </div>
                      {/* Acquaintance area */}
                      <div className="acquaintance-area">
                        <div className="header">
                          <Link to="/t3-supportsystem" target='_blank'><h6 className="title">T3 Support system</h6></Link>
                          <span />
                        </div>
                      </div>
                      {/* Acquaintance area */}
                    </div>
                    {/* single team inner End */}
                  </div>
                  {/* single team End */}
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">

                    <div className="team-inner-two inner">
                      <div className="thumbnail webvision-portfolio-image">
                        <img
                          src={require('./images/work-showcase/appointment.webp')}
                          alt="appointmentSystem"
                        />
                        <div className="view-content">
                          <Link to="/appointment" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                        </div>
                      </div>

                      <div className="acquaintance-area">
                        <div className="header">
                          <Link to="/appointment" target='_blank'><h6 className="title">Appointment System</h6></Link>
                          <span />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">

                    <div className="team-inner-two inner">
                      <div className="thumbnail webvision-portfolio-image">
                        <img
                          src={require('./images/work-showcase/food.webp')}
                          alt="food" />
                        <div className="view-content">
                          <Link to="/food-delivery" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                        </div>
                      </div>

                      <div className="acquaintance-area">
                        <div className="header">
                          <Link to="/food-delivery" target='_blank'><h6 className="title"> Food delivery App </h6></Link>
                          <span />
                        </div>
                      </div>

                    </div>

                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">

                    <div className="team-inner-two inner">
                      <div className="thumbnail webvision-portfolio-image">
                        <img src={require('./images/work-showcase/ride.webp')} alt="ride" />
                        <div className="view-content">
                          <Link to="/rideapp" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                        </div>
                      </div>

                      <div className="acquaintance-area">
                        <div className="header">
                          <Link to="/rideapp" target='_blank'> <h6 className="title">Ride App</h6></Link>
                          <span />
                        </div>
                      </div>

                    </div>

                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    {/* single team inner */}
                    <div className="team-inner-two inner">
                      <div className="thumbnail webvision-portfolio-image">
                        <img
                          src={require('./images/work-showcase/ak_plus.webp')}
                          alt="T3Supportsystem"
                        />
                        <div className="view-content">
                          <Link to="/akplus" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                        </div>
                      </div>
                      {/* Acquaintance area */}
                      <div className="acquaintance-area">
                        <div className="header">
                          <Link to="/akplus" target='_blank'><h6 className="title">AKPlus </h6></Link>
                          <span />
                        </div>
                      </div>
                      {/* Acquaintance area */}
                    </div>
                    {/* single team inner End */}
                  </div>
                  {/* single team End */}



                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    {/* single team inner */}
                    <div className="team-inner-two inner">
                      <div className="thumbnail webvision-portfolio-image">
                        <img
                          src={require('./images/work-showcase/Model.webp')}
                          alt="T3Supportsystem"
                        />
                        <div className="view-content">
                          <Link to="/model" target='_blank' className="view-more"><i className="fa fa-eye"></i> View more</Link>
                        </div>
                      </div>
                      {/* Acquaintance area */}
                      <div className="acquaintance-area">
                        <div className="header">
                          <Link to="/model" target='_blank'><h6 className="title">Model </h6></Link>
                          <span />
                        </div>
                      </div>
                      {/* Acquaintance area */}
                    </div>
                    {/* single team inner End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    </>
  )
}
export default Workshow