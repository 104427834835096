
const Education = () => {

    return(
        <>
           {/* start breadcrumb area */}
           <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md- col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">Education website</h1>
                            <p className="sub-title font-static-white">
                            Informative education website offering resources, courses, and insights to support  {" "}

                                <br />
                                learning journeys.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row" style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/education-1.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About Education</span>
                                    <h3 className="title">Education</h3>
                                    <p className="disc">
                                    Welcome to our education website, a virtual gateway to limitless learning opportunities designed to ignite curiosity and foster intellectual growth. Here, we believe that education is a lifelong journey, and our platform is dedicated to providing a rich and diverse range of resources to learners of all ages and backgrounds. Explore an array of subjects, from mathematics and literature to science and the arts, all presented through engaging multimedia content, interactive lessons, and thought-provoking articles. Whether you're a student seeking academic support, an educator searching for innovative teaching strategies, or an inquisitive individual hungry for knowledge, our website is your companion in the pursuit of enlightenment.
                                    </p>
                                    <p className="disc">
                                    As you navigate our user-friendly interface, you'll discover study guides, practice quizzes, and comprehensive tutorials that cater to different learning styles. Delve into educational blogs that discuss the latest trends in pedagogy, ed-tech innovations, and the psychology of learning. Join a vibrant community of learners, educators, and experts who share their insights, experiences, and strategies for personal and professional development. Our commitment to education goes beyond traditional boundaries, embracing the digital age and harnessing its potential to democratize knowledge. Embark on your educational journey with us and unlock the doors to a world of inspiration, growth, and empowerment.

                                    </p>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50"  >
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">Education  Features:</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                The education website offers diverse courses, interactive learning resources, expert instructors, and personalized learning paths to empower learners.
                                </p>
                            </div>
                        </div>
                        <div className='row flex-direction-column-reverse' style={{alignItems:'center'}}>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="product-details-main-inner">
                                    <div className="para">
                                        <h5 className="title"> Diverse Course Catalog: </h5>
                                        <p className="disc">
                                    Offer a wide range of courses covering various subjects and levels, catering to different educational needs and interests.
                                        </p>
                                    </div>
                                    <div className="para">
                                        <h5 className="title">Interactive Learning Materials: </h5>
                                        <p className="disc">
                                            {" "}
                                            Provide multimedia content such as videos, animations, and simulations to engage learners and enhance understanding.
                                        </p>
                                    </div>
                                    <div className="para">
                                        <h5 className="title">Personalized Learning Paths: </h5>
                                        <p className="disc">
                                        Create adaptive learning experiences that adjust based on learners' progress and performance, ensuring tailored education.

                                        </p>
                                    </div>
                                
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="big-bg-porduct-details">
                                    <img
                                        src={require('./images/gallery/inner-image/education-3.webp')}
                                        alt="T3 Web World"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img 
                               src={require('./images/gallery/inner-image/banner/education.webp')}
                                alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
 }
 export default Education