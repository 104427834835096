import { Link } from 'react-router-dom';

const Projectfive = () => {
  return (
    <>
      <>
        {/* start breadcrumb area */}
        <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                <h1 className="title">Portfolio Details</h1>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="bread-tag">
                  <Link to="/">Home</Link>
                  <span> / </span>
                  <Link className="active">
                    Portfolio Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end breadcrumb area */}
        {/* project details image area */}
        <div className="rts-project-details-area rts-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="big-bg-porduct-details">
                  <img
                    src={require('./images/product/bg-lg-02.png')}
                    alt="Business_Finbiz"
                  />
                  <div className="project-info">
                    <div className="info-head">
                      <h5 className="title">Project Information</h5>
                    </div>
                    <div className="info-body">
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-user" />
                        </div>
                        <div className="info-details">
                          <span>Project Name:</span>
                          <h6 className="name">Koladiya</h6>
                        </div>
                      </div>
                      {/* end single info */}
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-user" />
                        </div>
                        <div className="info-details">
                          <span>Clients:</span>
                          <h6 className="name">Koladiya Family</h6>
                        </div>
                      </div>
                      {/* end single info */}
                      {/* single info */}
                      <div className="single-info">
                        <div className="info-ico">
                          <i className="fas fa-layer-group" />
                        </div>
                        <div className="info-details">
                          <span>Category:</span>
                          <h6 className="name">Family information</h6>
                        </div>
                      </div>
                      {/* end single info */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt--70 mb--50">
              <div className="col-12">
                <div className="product-details-main-inner">
                  <span>Growth Solution</span>
                  <h3 className="title">Our Business Growth</h3>
                  <p className="disc">
                    We have taken this organization online in which we have collected
                    all the information about each member of the family online and
                    given them an ID password through which they can login and enter
                    their family information and can also be viewed the information of
                    other family member's data according to the permission,
                  </p>
                  <p className="italic">
                    “Koladiya Parivar is a social organization, which is running in
                    the different different city of the state Gujarat-India, with the
                    aim that every member of the family gets personal development,
                    every member of the family gets internal business, every student
                    of the family gets good education and educational information.”
                  </p>
                </div>
              </div>
            </div>
            <div className="row g-5 mb--60">
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-04.png')} alt="" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-05.png')} alt="" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img src={require('./images/product/d-06.png')} alt="" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-12">
                <h3 className="title mb--0">The Challenge Of Project</h3>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pr--80">
                <div className="single-project-details-challenge">
                  <p className="details">
                    Member of this organization can search the data by business, by
                    education, by blood group, by village name and contact the head of
                    the family member.
                  </p>
                  <p className="details">
                    With that every members can see the city wise core team of the
                    parivar, and contact them any time for any kind of help or
                    support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* project details image area end */}
      </>

    </>
  )
}
export default Projectfive;