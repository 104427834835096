import { FaCheck } from "react-icons/fa"
import { Link } from "react-router-dom"
import { HiOutlineArrowRight } from "react-icons/hi"
import { useState } from "react"

const Sass = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleCloseSidebar = () => {
        setIsSidebarOpen(false);
    };

    const handleMenuItemClick = () => {
        handleCloseSidebar(); // Close the sidebar when a menu item is clicked
        window.scrollTo(0, 0); // Scroll to the top of the page
    };
    return (
        <>
            <>
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">SAAS Development</h1>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="bread-tag">
                                    <Link to="/" className="active">Home</Link>
                                    <span> / </span>
                                    <Link to="/service" className="active">Service</Link>
                                    <span> / </span>
                                    <Link>
                                        SAAS Development
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* start service details area */}
                <div className="rts-service-details-area rts-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                                {/* service details left area start */}
                                <div className="service-detials-step-1">
                                    <div className="thumbnail">
                                        <img
                                            src={require('./images/service/inner-imges/SAAS_development.webp')}
                                            alt="business-area"
                                        />
                                    </div>
                                    <h4 className="title">About SAAS Development</h4>
                                    <p className="disc">
                                        Welcome to our SAAS Development services, where innovation meets
                                        functionality to empower your business for unparalleled success.
                                    </p>
                                    <p className="disc">
                                        As a leading provider of Software as a Service (SAAS) solutions,
                                        we are committed to crafting cutting-edge cloud-based applications
                                        that perfectly align with your unique needs and aspirations.
                                    </p>
                                    <p className="disc">
                                        Our skilled team of developers will collaborate closely with your
                                        company, ensuring a deep understanding of your requirements and
                                        delivering customized SAAS solutions. By harnessing the potential
                                        of cloud technology, we aim to optimize your operations, boost
                                        productivity, and create seamless user experiences. Embrace the
                                        future of business efficiency with our exceptional SAAS
                                        Development services.
                                    </p>
                                </div>
                                <div className="row g-5 mt--30 mb--40">
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/01.webp')}
                                                    alt=""
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">Performance Metrics Tracking</h6>
                                                <p className="disc">
                                                    We have an arsenal of Quality Assurance (QA) engineers who
                                                    can efficiently monitor your SaaS app and track its
                                                    performance.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/02.webp')}
                                                    alt=""
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">SAAS Development &amp; Optimization</h6>
                                                <p className="disc">
                                                    Webvision team of experts can optimize your application to
                                                    ensure you get the best out of your Saas application.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/03.webp')}
                                                    alt=""
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">Security</h6>
                                                <p className="disc">
                                                    Security and privacy are vital to your growth as a business.
                                                    At Vision Infotech, we take privacy and security as a top
                                                    priority.
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                    <div className="col-lg-6">
                                        {/* single service details card */}
                                        <div className="service-details-card">
                                            <div className="thumbnail">
                                                <img
                                                    src={require('./images/service/icon/04.webp')}
                                                    alt=""
                                                    className="icon"
                                                />
                                            </div>
                                            <div className="details">
                                                <h6 className="title">SAAS Platform For Mobiles</h6>
                                                <p className="disc">
                                                    Our team can also build SaaS applications for mobiles.
                                                    Considering most consumers are mobile users
                                                </p>
                                            </div>
                                        </div>
                                        {/* single service details card End */}
                                    </div>
                                </div>
                                {/* service details left area end */}
                                <div className="service-detials-step-3 mt--70 mt_md--50">
                                    <h4 className="title">Our SAAS Development Services Include:</h4>
                                    <p className="disc">
                                        Catalysts for change before fully tested markets are maintain
                                        wireless scenarios after intermandated applications predominate
                                        revolutionary.
                                    </p>
                                    <div className="row g-5 align-items-center">
                                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <ul className="list-unstyled benefits-two__points ml-0">
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Requirements Gathering</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Architecture and Design</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Development</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Testing and Quality Assurance</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                                            <ul className="list-unstyled benefits-two__points ml-0">
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Deployment and Hosting</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Maintenance and Support</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>User Management and Access Control</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="icon insur-icon-svg">
                                                        <i> <FaCheck /> </i>
                                                    </div>
                                                    <div className="text">
                                                        <p>Analytics and Reporting</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*rts blog wizered area */}
                            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                                {/* single wizered start */}
                                <div className="rts-single-wized Categories service">
                                    <div className="wized-header">
                                        <h5 className="title">Our Services</h5>
                                    </div>
                                    <div className="wized-body">
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/web-design" onClick={handleMenuItemClick} >
                                                    Web Designing{" "}
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/ui-ux" onClick={handleMenuItemClick}>
                                                    UI-UX Designing  <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/graphics" onClick={handleMenuItemClick}>
                                                    Graphics Designing
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/logo" onClick={handleMenuItemClick}>
                                                    Logo Designing <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/web-development" onClick={handleMenuItemClick}>
                                                    Web Development <i className="far "> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/app-development" onClick={handleMenuItemClick}>
                                                    App Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/sass" className="active" onClick={handleMenuItemClick}>
                                                    SAAS Development<i className="far active"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/cms" onClick={handleMenuItemClick}>
                                                    CMS Development <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/payment-gateway" onClick={handleMenuItemClick}>
                                                    Payment Gateway Integration{" "}
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris End */}
                                        <ul className="single-categories">
                                            <li>
                                                <Link to="/digital" onClick={handleMenuItemClick}>
                                                    Digital Marketing
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                        {/* single categoris */}
                                        <ul className="single-categories active">
                                            <li>
                                                <Link to="/branding" onClick={handleMenuItemClick}>
                                                    Branding
                                                    <i className="far"> <HiOutlineArrowRight />  </i>
                                                </Link>
                                            </li>
                                        </ul>
                                        {/* single categoris End */}
                                    </div>
                                </div>

                                <div className="rts-single-wized contact service">
                                    <div className="wized-header">
                                        <Link to="/">
                                            <img
                                                src={require('./images/logo/white-logo.png')}
                                                alt="Business_logo"
                                            />
                                        </Link>
                                    </div>
                                    <div className="wized-body">
                                        <h5 className="title" >Need Help? We Are Here To Help You</h5>
                                        <Link to="/contact" target="_blank" className="rts-btn btn-primary">
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                                {/* single wizered End */}
                            </div>
                            {/* rts- blog wizered end area */}
                        </div>
                    </div>
                </div>
                {/* End service details area */}
            </>

        </>
    )
}
export default Sass;