
const T3Webworld = () => {
    return (

        <>
            {/* start breadcrumb area */}
            <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-6 col-md- col-sm-12 col-12 breadcrumb-1">
                            <h1 className="title">T3 Web World</h1>
                            <p className="sub-title font-static-white">
                            T3webworld: Your Gateway to Innovative Web {" "}                               
                                <br />
                                Solutions and Digital Excellence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* end breadcrumb area */}
            {/* project details image area */}
            <div className="rts-project-details-area rts-section-gap">
                <div className="container">
                    <div className="row" style={{alignItems:'center'}}>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <img
                                    src={require('./images/gallery/inner-image/t3-web-world-1.webp')}
                                    alt="T3 Web World"
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="big-bg-porduct-details">
                                <div className="product-details-main-inner">
                                    <span>About T3 Web World</span>
                                    <h3 className="title">T3 Web World</h3>
                                    <p className="disc">
                                        Presenting T3 Web World: A concept conceived for the common man,
                                        by the common man, and dedicated to the common man. At its core,
                                        this ambitious project is rooted in the noble vision of
                                        propelling the Digital India initiative forward. T3 Web World
                                        stands as a beacon of empowerment, inviting individuals from all
                                        walks of life to partake in its offerings.
                                    </p>
                                    <p className="disc">
                                        The essence of this endeavor lies in enabling every participant
                                        to embrace the digital landscape. With T3 Web World, individuals
                                        can independently create their own websites without external
                                        assistance, thus democratizing the process. Furthermore, the
                                        platform empowers users with the freedom to update their
                                        websites at their convenience, fostering a sense of autonomy
                                        over their online presence.
                                    </p>
                                    <p className="disc">
                                        A unique and compelling facet of this project is its
                                        incentivization strategy. Upon registration, the system
                                        intelligently allocates a portion of the funds to those who
                                        introduced the new user. This ingenious approach not only
                                        encourages higher participation rates but also fosters a sense
                                        of community as users share this beneficial platform within
                                        their networks.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-feature-area">
                <div className="container">
                    <div className="row mb--50">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="product-details-main-inner mb-5 text-center">
                                <h3 className="title">T3 Web World Features</h3>
                                <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                    Certainly, here are some key features that can be highlighted for
                                    the T3 Web World project:
                                </p>
                            </div>
                        </div>
                        <div className='row flex-direction-column-reverse'>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="product-details-main-inner">
                                    <div className="para">
                                        <h5 className="title">Empowering Accessibility:</h5>
                                        <p className="disc">
                                            T3 Web World is a platform that democratizes the website
                                            creation process, allowing individuals of all backgrounds to
                                            effortlessly establish their online presence without technical
                                            expertise.
                                        </p>
                                    </div>
                                    <div className="para">
                                        <h5 className="title">User-Centric Creation:</h5>
                                        <p className="disc">
                                            {" "}
                                            With a user-friendly interface, participants can craft and
                                            personalize their websites according to their unique vision,
                                            enhancing their digital representation.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="big-bg-porduct-details">
                                    <img
                                        src={require('./images/gallery/inner-image/t3-web-world-3.webp')}
                                        alt="T3 Web World"
                                    />
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
            <div className="rts-project-details-area">
                <div className="container-fulid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="thumbnail">
                                <img 
                                src={require('./images/gallery/inner-image/banner/t3-web-world-banner.webp')}
                                alt="T3 Web World" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default T3Webworld;