import { BsFacebook } from "react-icons/bs"
import { BsLinkedin } from "react-icons/bs"
import { BsYoutube } from "react-icons/bs"
import { BsInstagram, BsEnvelope } from "react-icons/bs"
import { BiTimeFive } from "react-icons/bi"
import { IoMdClose } from "react-icons/io"
import React, { useEffect, useState, } from 'react';
import { Link, NavLink } from "react-router-dom";

import Logo from '../pages/images/logo/logo-1.svg';
const Navbar = () => {

  const [isSticky, setIsSticky] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // ative
  const [openMenuIndex, setOpenMenuIndex] = useState(null);

  const toggleMenu = (index) => {
    setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  // end active
  const handleMenuBtnClick = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleMenuItemClick = () => {
    handleCloseSidebar(); // Close the sidebar when a menu item is clicked
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <>
      {/* start header area */}
      {/* header-one */}
      <header className={`header--sticky  ${isSticky ? 'sticky' : ''}`}>
        <div className="header-top header-top-one bg-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-xl-block d-none">
                <div className="left">
                  <div className="mail">
                    <Link to="mailto:support@webvisioninfotech.com">
                      <i className="fal"><BsEnvelope /></i>
                      support@webvisioninfotech.com
                    </Link>
                  </div>
                  <div className="working-time">
                    <p>
                      <i className="fal"><BiTimeFive /></i> Working : 9:30 AM To 6:30 PM
                      IST
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-xl-block d-none">
                <div className="right">

                  <ul className="social-wrapper-one">
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/web-vision-infotech"
                        target="_blank"
                      >
                        <i className="  fab" >
                          <BsLinkedin />
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.facebook.com/webvision22"
                        target="_blank"
                      >
                        <i className="  fab " >
                          <BsFacebook />
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.youtube.com/channel/UC-aRB5UvF1kj1KuUS85qkQA"
                        target="_blank"
                      >
                        <i className="  fab " >
                          <BsYoutube />
                        </i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/webvisioninfotech/"
                        target="_blank"
                      >
                        <i className="  fab" >
                          <BsInstagram />
                        </i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-main-one bg-white">
          <div className="container">
            <div className="row" style={{alignItems:'center'}}>
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
                <div className="thumbnail">
                  <Link to="/">
                    <img
                      src={require('../pages/images/logo/logo-1.png')}
                      alt="finbiz-logo" />
                  </Link>
                </div>
              </div>
              <div className=" col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
                <div className="main-header">
                  <nav className="nav-main mainmenu-nav d-none d-xl-block">
                    <ul className="mainmenu">
                      <li>
                        <NavLink exact="true" to="/"  className="mylink" onClick={handleMenuItemClick}> Home </NavLink>
                      </li>
                      <li>
                        <NavLink exact="true" to="/AboutUs" className="mylink" onClick={handleMenuItemClick}>About Us</NavLink>
                        {/* <ul className="submenu menu-link3">
                          <li>
                            <NavLink to="/our-team" className="sub-menu-link" onClick={handleMenuItemClick}>
                              Our Team
                            </NavLink>
                          </li>
                        </ul> */}
                      </li>
                      <li className="has-droupdown service-menu">
                        <NavLink to="/service" className="mylink" onClick={handleMenuItemClick}>Services</NavLink>
                        <ul className="submenu menu-link3">
                          <li>
                            <NavLink exact="true" to="/web-design" className="mylink sub-menu-link" onClick={handleMenuItemClick}>Web Designing</NavLink>
                          </li>
                          <li>
                            <NavLink exact="true" to="/sass" className="mylink" onClick={handleMenuItemClick}>SAAS Development</NavLink>
                          </li>
                          <li>
                            <NavLink exact="true" to="/ui-ux" className="mylink" onClick={handleMenuItemClick}>UI-UX Designing</NavLink>
                          </li>
                          <li>
                            <NavLink exact="true" to="/cms" className="mylink" onClick={handleMenuItemClick} >CMS Development</NavLink>
                          </li>
                          <li>
                            <NavLink exact="true" to="/graphics" className="mylink" onClick={handleMenuItemClick}>Graphics Design</NavLink>
                          </li>
                          <li>
                            <NavLink exact="true" to="/payment-gateway" className="mylink" onClick={handleMenuItemClick}>Payment Gateway Integration</NavLink>
                          </li>
                          <li>
                            <NavLink exact="true" to="/logo" className="mylink" onClick={handleMenuItemClick}>Logo Design</NavLink>
                          </li>
                          <li>
                            <NavLink exact="true" to="/digital" className="mylink" onClick={handleMenuItemClick}>Digital Marketing</NavLink>
                          </li>
                          <li>
                            <NavLink exact="true" to="/web-development" className="mylink" onClick={handleMenuItemClick}>Web Development</NavLink>
                          </li>
                          <li>
                            <NavLink exact="true" to="/branding" className="mylink" onClick={handleMenuItemClick}>Branding</NavLink>
                          </li>
                          <li>
                            <NavLink exact="true" to="/app-development" className="mylink" onClick={handleMenuItemClick}>App Development</NavLink>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <NavLink exact="true" to="/careers" className="mylink" onClick={handleMenuItemClick}>Careers</NavLink>
                      </li>
                      <li>
                        <NavLink exact="true" to="/ContactUs" className="mylink" onClick={handleMenuItemClick}>Contact Us</NavLink>
                      </li>
                    </ul>
                  </nav>
                  <div className="button-area  text-end">

                    <Link to="/workshow"
                      className="rts-btn btn-primary ml--20 ml_sm--5 header-one-btn quote-btn"
                      target="_blank"
                    >
                      Work Showcases
                    </Link>
                    <button
                      id="menu-btn"
                      className="menu rts-btn btn-primary-alta ml--20 ml_sm--5 d-block d-xl-none"
                      onClick={handleMenuBtnClick}
                    >
                      <img
                        className="menu-dark"
                        src={require('../pages/images/icon/menu.png')}
                        alt="Menu-icon"
                      />
                      <img
                        className="menu-light"
                        src={require('../pages/images/icon/menu-light.png')}
                        alt="Menu-icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* End header area */}
      <div id="side-bar" className={`side-bar ${isSidebarOpen ? 'show' : ''}`}>
        <button className="close-icon-menu" onClick={handleCloseSidebar}>
          <i className="far"> <IoMdClose /> </i>
        </button>
        {/* inner menu area desktop start */}
        <div className="rts-sidebar-menu-desktop">
          <Link to="/" className="logo-1">
            <img
              className="logo"
              src={Logo}
              alt="finbiz_logo"
            />
          </Link>
          <Link to="/" className="logo-2">
            <img
              className="logo"
              src="assets/images/logo/logo-1.svg"
              alt="finbiz_logo"
            />
          </Link>
          <Link to="/" className="logo-3">
            <img
              className="logo"
              src="assets/images/logo/logo-1.svg"
              alt="finbiz_logo"
            />
          </Link>
          <Link to="/" className="logo-4">
            <img
              className="logo"
              src="assets/images/logo/logo-1.svg"
              alt="finbiz_logo"
            />
          </Link>
          <div className="body d-none d-xl-block">
            <p className="disc">
              We must explain to you how all seds this mistakens idea denouncing
              pleasures and praising account.
            </p>
            <div className="get-in-touch">
              {/* title */}
              <div className="h6 title">Get In Touch</div>
              {/* title End */}
              <div className="wrapper">
                {/* single */}
                <div className="single">
                  <i className="fas fa-phone-alt" />
                  <a href="#">+91 8980384148</a>
                </div>
                {/* single ENd */}
                {/* single */}
                <div className="single">
                  <i className="fas fa-envelope" />
                  <a href="#">support@webvisioninfotech.com</a>
                </div>
                {/* single ENd */}
                {/* single */}
                <div className="single">
                  <i className="fas fa-globe" />
                  <a href="#">www.webvision.com</a>
                </div>
                {/* single ENd */}
                {/* single */}
                {/* <div className="single">
                  <i className="fas fa-map-marker-alt" />
                  <a href="#">
                    312-313, Sovereign Shoppers, Beside Sindhu Seva Samiti School,
                    Adajan, Surat, Gujarat
                  </a>
                </div> */}
                {/* single ENd */}
              </div>
              <div className="social-wrapper-two menu">
                <Link to="https://www.facebook.com/webvision22" target="_blank">
                  <i className="fab fa-facebook-f" />
                </Link>
                <Link to="https://www.linkedin.com/company/web-vision-infotech" target="_blank">
                  <i className="fab fa-linkedin" />
                </Link>
                <Link to="https://www.youtube.com/channel/UC-aRB5UvF1kj1KuUS85qkQA" target="_blank">
                  <i className="fab fa-instagram" />
                </Link>
                <Link to="https://www.instagram.com/webvisioninfotech/" target="_blank">
                  <i className="fab fa-youtube" />
                </Link>
                {/* <a href="#"><i className="fab fa-linkedin"></i></a> */}
              </div>
            </div>
          </div>
          <div className="body-mobile d-block d-xl-none">

            <nav className="nav-main mainmenu-nav">
              <ul className="mainmenu">
                <li>
                  <Link to="/"  className="mylink"
                    onClick={handleMenuItemClick}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/AboutUs"  className="mylink"
                    onClick={handleMenuItemClick}
                  >
                    About Us
                  </Link>
                </li>
                {/* <li
                  className={`has-droupdown ${openMenuIndex === 1 ? 'active' : ''}`}
                  onClick={() => toggleMenu(1)}
                >
                  <Link className="mylink"
                  >
                    About Us
                  </Link>
                  <ul className={`submenu menu-link3 ${openMenuIndex === 1 ? 'active' : ''}`}>
                    <li>
                      <Link to="/about" className="sub-menu-link"
                        onClick={handleMenuItemClick}
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/our-team" className="sub-menu-link"
                        onClick={handleMenuItemClick}
                      >
                        Our Team
                      </Link>
                    </li>
                  </ul>
                </li> */}
                <li
                  className={`has-droupdown service-menu ${openMenuIndex === 2 ? 'active' : ''
                    }`}
                  onClick={() => toggleMenu(2)}
                >
                  <Link className="mylink" >
                    Services
                  </Link>
                  <ul className={`submenu menu-link3 ${openMenuIndex === 2 ? 'active' : ''}`}>
                    <li>
                      <Link to="/service" className="mylink"
                        onClick={handleMenuItemClick}
                      >Our Services</Link>
                    </li>
                    <li>

                      <Link to="/web-design" className="sub-menu-link"
                        onClick={handleMenuItemClick}
                      >
                        Web Designing
                      </Link>
                    </li>
                    <li>
                      <Link to="/web-development" className="mylink"
                        onClick={handleMenuItemClick}
                      >Web Development</Link>
                    </li>
                    <li>
                      <Link to="/ui-ux" className="mylink"
                        onClick={handleMenuItemClick}
                      >UI-UX Designing</Link>
                    </li>
                    <li>
                      <Link to="/app-development" className="mylink" onClick={handleMenuItemClick}>App Development</Link>
                    </li>
                    <li>
                      <Link to="/graphics" className="mylink" onClick={handleMenuItemClick}>Graphics Design</Link>
                    </li>
                    <li>
                      <Link to="/sass" className="mylink" onClick={handleMenuItemClick}>SAAS Development</Link>
                    </li>
                    <li>
                      <Link to="/logo" className="mylink" onClick={handleMenuItemClick}>Logo Design</Link>
                    </li>
                    <li>
                      <Link to="/cms" className="mylink" onClick={handleMenuItemClick}>CMS Development</Link>
                    </li>
                    <li>
                      <Link to="/payment-gateway" className="mylink" onClick={handleMenuItemClick}>Payment Gateway Integration</Link>
                    </li>
                    <li>
                      <Link to="/digital" className="mylink" onClick={handleMenuItemClick}>Digital Marketing</Link>
                    </li>
                    <li>
                      <Link to="/branding" className="mylink" onClick={handleMenuItemClick}>Branding</Link>
                    </li>
                    {/* ... Other service links */}
                  </ul>
                </li>
                <li>
                  <Link to="/careers" className="mylink"
                    onClick={handleMenuItemClick}
                  >
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="/ContactUs" className="mylink"
                    onClick={handleMenuItemClick}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="social-wrapper-two menu mobile-menu">
            <Link to="https://www.facebook.com/webvision22" target="_blank">
                  <i className="fab fa-facebook-f" />
                </Link>
                <Link to="https://www.linkedin.com/company/web-vision-infotech" target="_blank">
                  <i className="fab fa-linkedin" />
                </Link>
              <Link to="https://www.youtube.com/channel/UC-aRB5UvF1kj1KuUS85qkQA" target="_blank">
                  <i className="fab fa-instagram" />
                </Link>
                <Link to="https://www.instagram.com/webvisioninfotech/" target="_blank">
                  <i className="fab fa-youtube" />
                </Link>
            </div>
            <Link to="/workshow"
              className="rts-btn btn-primary ml--20 ml_sm--5 header-one-btn quote-btnmenu"
              onClick={handleMenuItemClick}
            >
              Work Showcases
            </Link>
          </div>
        </div>
        {/* inner menu area desktop End */}
      </div>
      <div className="search-input-area">
        <div className="container">
          <div className="search-input-inner">
            <div className="input-div">
              <input
                id="searchInput1"
                className="search-input"
                type="text"
                placeholder="Search by keyword or #"
              />
              <button>
                <i className="far fa-search" />
              </button>
            </div>
          </div>
        </div>
        <div id="close" className="search-close-icon">
          <i className="far fa-times" />
        </div>
      </div>
    </>
  )
}
export default Navbar

